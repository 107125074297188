import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import Button, { ButtonProps } from "antd/lib/button";
import { FC, isValidElement } from "react";
import { Link } from "react-router-dom";
import Translate from "../../Translate";
import SNIcon from "../SNIcon";
import { default as AntTooltip, default as SNTooltip } from "../SNTooltip";
import "./index.scss";

export interface SNButtonProps extends Omit<ButtonProps, "icon"> {
  iconcolor?: string;
  href?: string;
  loading?: boolean | object;
  title?: any;
  link?: any;
  tooltip?: string;
  icon?: IconProp | IconName;
  iconProps?: FontAwesomeIconProps;
  tips?: string;
}

const SNButton: FC<SNButtonProps> = (props) => {
  const {
    link,
    title,
    icon,
    iconcolor,
    href,
    tooltip,
    size,
    className,
    iconProps,
    tips,
    ...newprops
  } = props;

  const getIcon = (icon: any, iconProps: FontAwesomeIconProps) => {
    if (!icon && !iconProps) return null;
    if (isValidElement(icon)) return icon;

    if (typeof icon === "string" && icon.startsWith("fa-")) {
      const iconName: any = icon.replace("fa-", "");
      return <SNIcon icon={iconName} />;
    }

    return <SNIcon icon={icon} {...iconProps} color={iconcolor} />;
  };

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <AntTooltip text={tooltip || ""}>
          <Button icon={getIcon(icon, iconProps)} />
        </AntTooltip>
      </a>
    );
  }
  if (link) {
    return (
      <Link to={link}>
        <Button
          {...newprops}
          icon={getIcon(icon, iconProps)}
          className={`${className || ""} sn-button`}
        >
          {title && typeof title === "string" ? (
            <Translate i18nKey={title} />
          ) : (
            title
          )}
        </Button>
      </Link>
    );
  }
  if (tips) {
    return (
      <SNTooltip text={tips}>
        <Button
          {...newprops}
          icon={getIcon(icon, iconProps)}
          className={`${className || ""} sn-button ${
            title ? "" : "sn-btn-icon-only"
          }`}
        >
          {title && typeof title === "string" ? (
            <Translate i18nKey={title} />
          ) : (
            title
          )}
        </Button>
      </SNTooltip>
    );
  }
  return (
    <Button
      {...newprops}
      icon={getIcon(icon, iconProps)}
      className={`${className || ""} sn-button`}
    >
      {title && typeof title === "string" ? (
        <Translate i18nKey={title} />
      ) : (
        title
      )}
    </Button>
  );
};

export default SNButton;
