import { combineReducers } from "redux";
import app from "../reducers/appReducer";
import auth from "../reducers/authReducer";
import cum from "../reducers/companiesReducer";
import comAuth from "../reducers/companyAuth";
import companyInfo from "../reducers/companyInfoReducer";
import comSetting from "../reducers/comsettingsReducer";
import invoiceSettings from "../reducers/invoiceReducer";
import myself from "../reducers/myselfReducer";
import permissions from "../reducers/permissionReducer";
import stats from "../reducers/statsReducer";
import theme from "../reducers/themeReducer";
import units from "../reducers/unitsReducer";
import user from "../reducers/userReducer";
export const appReducer = combineReducers({
  app,
  comSetting,
  cum,
  auth,
  user,
  comAuth,
  stats,
  invoiceSettings,
  myself,
  companyInfo,
  theme,
  units,
  permissions,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = {
      auth: {
        loginTime: state.auth.loginTime,
      },
    };
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
