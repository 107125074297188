import { createSlice } from "@reduxjs/toolkit";
import { CompanyData } from "../../screens/MyPage/MyCompanies/data.t";

const initialState: {
  companies: CompanyData[];
  loaded: boolean;
} = {
  companies: [],
  loaded: false,
};
const companiesSlice = createSlice({
  name: "companiesTodos",
  initialState,
  reducers: {
    updateCompanyUserMap(state, action) {
      return {
        ...state,
        companies: action.payload,
        loaded: true,
      };
    },
  },
});

const { actions, reducer } = companiesSlice;

export const { updateCompanyUserMap } = actions;

export default reducer;
