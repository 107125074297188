import { createSlice } from "@reduxjs/toolkit";

export interface UnitData {
  name: string;
  short_form: string;
  unit_code: string;
}
const initialState: { data: UnitData[] } = {
  data: [],
};
const unitsSlice = createSlice({
  name: "unitTodos",
  initialState,
  reducers: {
    fetchUnits(state, action) {
      return {
        data: action.payload,
      };
    },
  },
});

const { actions, reducer } = unitsSlice;

export const { fetchUnits } = actions;

export default reducer;
