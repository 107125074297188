import { Form } from "antd";
import { FormItemProps, RuleObject } from "antd/lib/form";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import SNButton, { SNButtonProps } from "../../SNButton";
import "./index.scss";

export interface SNFormItemProps extends FormItemProps {
  type?: "checkbox" | "input" | "select" | "textarea";
  noTranslateLabel?: boolean;
  label?: any;
  required?: boolean;
  rules?: RuleObject[] | any;
  labelRight?: any;
  rightButton?: SNButtonProps;
  isLabel?: boolean;
  requiredMessage?: string;
  hideError?: boolean;
}

const SNFormItem: FC<SNFormItemProps> = (props) => {
  const {
    label,
    required,
    rules,
    type,
    className = "sn-form-item",
    labelRight,
    rightButton,
    noTranslateLabel,
    isLabel,
    requiredMessage,
    hideError,
    ...newProps
  } = props;
  const { t } = useTranslation();

  let rulesItem = rules || [];
  if (!!rules?.find((item) => item.type === "number")) {
    rulesItem = [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || (value && !isNaN(value))) {
            return Promise.resolve();
          }

          return Promise.reject(
            t("common.form.validation.number_invalid_number")
          );
        },
      }),
    ];
  }
  const itemLabel = () => {
    if (label && rightButton)
      return (
        <div className="sn-form-item-label-has-right">
          {typeof label === "string" ? t(label) : label}
          <div className="pull-right">
            <SNButton
              {...rightButton}
              type="link"
              icon="plus-circle"
              title="common.btn.add_new"
            />
          </div>
        </div>
      );
    if (labelRight)
      return (
        <div className="sn-form-item-label-has-right">
          {label && typeof label === "string" ? t(label) : label}{" "}
          <div className="pull-right">{labelRight}</div>
        </div>
      );

    return typeof label === "string" ? t(label) : label;
  };
  const handelClassName = () => {
    let name = "sn-form-item";
    if (className) {
      name += ` ${className}`;
    }
    if (type === "checkbox") {
      name += ` sn-checkbox-input-item`;
    }
    if (isLabel) {
      name += ` sn-form-item-isLabel`;
    }
    if (hideError) {
      name += ` sn-form-item-hide-error`;
    }
    return name;
  };
  return (
    <Form.Item
      label={itemLabel()}
      rules={[
        {
          required: required,
          message: requiredMessage && t(requiredMessage),
        },
        ...rulesItem,
      ]}
      className={handelClassName()}
      {...newProps}
    >
      {props.children}
    </Form.Item>
  );
};

export default SNFormItem;
