const API_VERSION = process.env.REACT_APP_API_VERSION;
const base = `/${API_VERSION}`;
const LOGIN = "/o/token/";
const REFRESH_TOKEN = "/o/token/";
const REVOKE_TOKEN = "/o/revoke_token/";
const TENANT_TOKEN = "/v4/tenant/token/";
const REGISTRATION = `${base}/auth/registration/`;
const MY_PROFILE = `${base}/user/profile/`;
const COMPANY = `${base}/company/`;
const MY_COMPANIES = `${base}/company/users/`;
const MY_PROFILE_COMPANY = `${base}/company/profiles/`;
const REGISTRATION_RESEND = `${base}/auth/registration/resend/`;
const RESET_PASSWORD = `${base}/auth/password-reset/`;
const NOTIFICATION = `${base}/notification/`;
const NOTIFICATION_SETTINGS = `${base}/activity/event_type/settings/`;
const EMPLOYEE = `${base}/company/users/`;
const USER_PROFILE = `${base}/user/profile/`;
const CHANGE_PASSWORD = `${base}/auth/password-change/`;
const NEWSFEED = `${base}/newsfeed/`;
const appURL = {
  LOGIN,
  REFRESH_TOKEN,
  TENANT_TOKEN,
  REGISTRATION,
  MY_PROFILE,
  COMPANY,
  REVOKE_TOKEN,
  MY_COMPANIES,
  MY_PROFILE_COMPANY,
  REGISTRATION_RESEND,
  RESET_PASSWORD,
  NOTIFICATION,
  NOTIFICATION_SETTINGS,
  EMPLOYEE,
  CHANGE_PASSWORD,
  USER_PROFILE,
  NEWSFEED,
};
export default appURL;
