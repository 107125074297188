import { CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Typography, message, notification } from "antd";
import i18n from "../i18n";
import { fetchStatistics } from "../screens/Company/service";

const { Paragraph } = Typography;
//Showing success alert
export function successAlert(mess, noTranslate = false, fetchStats = true) {
  let description = noTranslate ? mess : i18n.t(mess || "");

  if (fetchStats) fetchStatistics();
  message.success(description, 4.5);
}

export function warningAlert(message, noTranslate = false, header) {
  notification["warning"]({
    message: i18n.t("common.form.warning_title"),
    top: 64,
    description: (
      <p className="text-description">
        {noTranslate ? message : i18n.t(message || "")}
      </p>
    ),
    zIndex: 99999,
    icon: <ExclamationCircleFilled style={{ color: "#ff9800" }} />,
  });
}
export function infoAlert(message, noTranslate = false, header) {
  notification["info"]({
    duration: 10,
    message: i18n.t("common.form.notification_title"),
    top: 64,
    description: (
      <p className="text-description">
        {noTranslate ? message : i18n.t(message || "")}
      </p>
    ),
    zIndex: 99999,
    icon: <ExclamationCircleFilled style={{ color: "#52c41a" }} />,
  });
}
export function errorAlert(message, fixedMessage) {
  notification["error"]({
    message: i18n.t(fixedMessage || "common.form.error_title"),
    top: 64,
    description: (
      <p className="text-description">
        {typeof message ? i18n.t(message) : message}
      </p>
    ),
    zIndex: 99999,
    icon: <CloseCircleFilled style={{ color: "#f50" }} />,
  });
}

export function serverAlert(message, fixedMessage) {
  let alertMessage = "";
  if (Array.isArray(message)) {
    alertMessage = (
      <Paragraph>
        {i18n.t("common.form.error_text")}
        <ul>
          {message.map((msg, key) => {
            if (msg.title)
              return (
                <li key={key}>
                  <span>
                    {msg.title && msg.title !== "non_field_errors" && (
                      <b>{msg.title} - </b>
                    )}
                    {msg.message}
                  </span>
                </li>
              );
            return <li key={key}>{i18n.t(msg.message || "")}</li>;
          })}
        </ul>
      </Paragraph>
    );
  } else {
    alertMessage = message;
  }

  notification["error"]({
    message: i18n.t(fixedMessage || "common.form.error_title"),
    top: 64,
    description: alertMessage,
    zIndex: 99999,
    icon: <CloseCircleFilled style={{ color: "#f50" }} />,
  });
}
