import axios from "axios";
import { HOST_API } from "../../config";

// ----------------------------------------------------------------------

const appAxios = axios.create({
  baseURL: HOST_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default appAxios;
