import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "chartjs-adapter-moment";
import "core-js/features/object/assign";
import "friendly-challenge/widget";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import WebFont from "webfontloader";
import MainApp from "./App";
import "./App.css";
import "./App.less";
import "./App.scss";
import Loader from "./components/Loader/Loader";
import { AuthProvider } from "./contexts/AuthContext";
import "./i18n";
import "./index.css";
import store, { persistor } from "./redux";
import * as serviceWorker from "./serviceWorker";
import "./utils/dayjs";
import "./utils/mapboxgl";
WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700", "sans-serif"],
  },
});
ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <MainApp />
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
