import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  notification: {
    notification_items: [],
    sticky_items: [],
    unread_count: 0,
    unseen_count: 0,
  },
  newNotification: false,
  processLoading: false,
  menuCollapsed: false,
};
const appSlice = createSlice({
  name: "appTodos",
  initialState,
  reducers: {
    loadingStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    loadingEnd(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addNotification(state, action) {
      return {
        ...state,
        notification: { ...action.payload },
      };
    },
    newNotificationEnable(state) {
      return {
        ...state,
        newNotification: true,
      };
    },
    newNotificationDisable(state) {
      return {
        ...state,
        newNotification: false,
      };
    },
    processStart(state) {
      return {
        ...state,
        processLoading: true,
      };
    },
    processEnd(state) {
      return {
        ...state,
        processLoading: false,
      };
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  loadingEnd,
  loadingStart,
  addNotification,
  processEnd,
  processStart,
  newNotificationDisable,
  newNotificationEnable,
} = actions;

export default reducer;
