import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import i18n from "../../i18n";
import "./loader.css";

class Loader extends Component {
  render() {
    const { style, customText, fullPageLoader } = this.props;
    return (
      <div className={fullPageLoader ? "full-page" : ""}>
        <div className="sn-loader" style={style}>
          <p className="text-center">
            {customText ? i18n.t(customText) : i18n.t("common.loader_text")}
            ...
          </p>
          <div className="text-center" style={{ marginTop: "10px" }}>
            {/* <Icon
              className=spinner fa-pulse fa-3x"
              style={{ color: "#1565C0" }}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(Loader);
