import { Modal } from "antd";
import Avatar, { AvatarProps } from "antd/lib/avatar";
import { useState } from "react";
import { formatNameAvatar } from "../../../lib/services";
import { CompanyUserData } from "../../../screens/MyPage/data.t";
import "./index.scss";

interface imageData {
  low?: string;
  med?: string;
  full?: string;
  created_at?: string;
  created_by?: CompanyUserData;
  title?: string;
}

interface SNAvatarProps extends AvatarProps {
  name?: any;
  image?: imageData;
}

function SNAvatar(props: SNAvatarProps) {
  const { name, src, style, image, ...restProps } = props;
  const char = formatNameAvatar(name);

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  const [previewVisible, setPreviewVisible] = useState(false);
  if (image)
    return (
      <div onClick={() => setPreviewVisible(true)}>
        <Avatar
          src={image.low}
          {...restProps}
          style={{
            ...style,
            cursor: "pointer",
            border: "1px solid #d9d9d9",
          }}
          alt=""
        >
          {!src && char}
        </Avatar>

        <Modal
          visible={previewVisible}
          title={image.title}
          footer={false}
          onCancel={() => setPreviewVisible(false)}
          className="sn-image-preview-modal"
          bodyStyle={{ padding: 0 }}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={image?.full || image?.med || image?.low}
          />
        </Modal>
      </div>
    );
  return (
    <Avatar
      src={src}
      style={{
        backgroundColor: !src && char && stringToHslColor(char, 70, 60),
        cursor: image && "pointer",

        ...style,
      }}
      {...restProps}
    >
      {!src && char}
    </Avatar>
  );
}

export default SNAvatar;
