import { Card, Progress } from "antd";
import Translate from "../../Translate";
import SNButton from "../SNButton";
import SNLoader from "../SNLoader";
import "./index.scss";
interface SNProcessLoaderProps {
  loading: boolean;
  progress?: number;
  onCancel?: () => any;
}

function SNProcessLoader(props: SNProcessLoaderProps) {
  const { loading, progress, onCancel } = props;
  const renderLoader = () => {
    if (progress)
      return (
        <Card>
          <Progress type="circle" percent={progress} />

          {onCancel && (
            <div style={{ textAlign: "center", marginTop: 6 }}>
              <SNButton
                type="link"
                title="common.btn.cancel"
                onClick={() => {
                  onCancel();
                }}
              />
            </div>
          )}
        </Card>
      );
    return (
      <SNLoader
        className="sn-process-spin"
        loaderStyle="dot"
        tip={<Translate i18nKey="common.loader_text" />}
      />
    );
  };
  return (
    <div
      className="sn-process-loader-container"
      style={{ display: loading ? "flex" : "none" }}
    >
      {renderLoader()}
    </div>
  );
}

export default SNProcessLoader;
