import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  data: null,
  isLoading: false,
  isSO: false,
};
const permissionSlice = createSlice({
  name: "permissionTodos",
  initialState,
  reducers: {
    fetchPermissionsStart(state) {
      return {
        ...state,
        isSO: false,
        isLoading: true,
      };
    },
    fetchPermissions(state, action) {
      return {
        ...state,
        isSO: false,
        data: action.payload,
      };
    },
    fetchPermissionsEnd(state) {
      return {
        ...state,
        isSO: false,
        isLoading: false,
      };
    },
    fetchSOPermissions(state) {
      return {
        isSO: true,
        isLoading: false,
      };
    },
  },
});

const { actions, reducer } = permissionSlice;
export const {
  fetchPermissions,
  fetchPermissionsStart,
  fetchPermissionsEnd,
  fetchSOPermissions,
} = actions;

export default reducer;
