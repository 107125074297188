import { PageLoading } from "@ant-design/pro-layout";
import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import SNPageNotFound from "../../components/SN/SNPageNotFound";
import SNProcessLoader from "../../components/SN/SNProcessLoader";
import { PROVIDER_URL, PUBLIC_URL } from "../../constant/app";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/reducers";
import ForgetPassword from "../auth/ForgetPassword";
import RegVerify from "../auth/RegVerify";
import TermsOfService from "../TOS";
import AuthorizedRoute from "./components/AuthorizedRoute";
import MobileDrawer from "./components/MobileDrawer";
import {
  CONFIRMATION_URL,
  FORGET_PASSWORD,
  LOGIN_URL,
  REGISTRATION_URL,
  RESEND_ACTIVATION_MAIL,
  RESET_PASSWORD,
} from "./rootUrls";
import { testServer } from "./service";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("../auth/Login")));
const Register = Loadable(lazy(() => import("../auth/Registration")));
const ResetPassword = Loadable(lazy(() => import("../auth/ResetPassword")));
const ResendMail = Loadable(lazy(() => import("../auth/ResendMail")));
const MaintainPage = Loadable(lazy(() => import("./components/MaintainPage")));
const Notification = Loadable(lazy(() => import("../Notification")));
const MyPage = Loadable(lazy(() => import("../MyPage")));
const Company = Loadable(lazy(() => import("../Company")));

const Root = (props) => {
  const processLoading = useSelector(
    (state: RootState) => state.app.processLoading
  );

  useEffect(() => {
    if (window.location.pathname !== "/maintenance") testServer();
  }, []);
  const userInfo = useAppSelector((state) => state.user);
  return (
    <>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (window.location.host === "snode.se")
              return (window.location.href = PROVIDER_URL);
            else {
              if (localStorage.getItem("accessToken"))
                return <Redirect to="/mypage" />;
              else return <Redirect to="/login" />;
            }
          }}
        />

        <Route exact path={LOGIN_URL} component={Login} />
        <Route exact path={REGISTRATION_URL} component={Register} />
        <Route
          exact
          path={CONFIRMATION_URL}
          render={(props) => {
            return <RegVerify {...props} />;
          }}
        />
        <Route exact path={FORGET_PASSWORD} component={ForgetPassword} />
        <Route exact path={RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={RESEND_ACTIVATION_MAIL} component={ResendMail} />
        <Route exact path="/maintenance" component={MaintainPage} />

        <AuthorizedRoute exact path="/notification/" component={Notification} />

        <AuthorizedRoute strict path="/mypage" component={MyPage} />
        <AuthorizedRoute strict path="/:uuid/" component={Company} />

        <AuthorizedRoute
          strict
          path="/notification/:id"
          component={Notification}
        />

        <Route
          exact
          path="/:id"
          render={(props) => {
            let { id } = props.match.params;
            if (id.match(/^([a-zA-Z]{2}\d{4}[a-zA-Z]{2})$/g))
              return (window.location.href = `${PUBLIC_URL}${id}`);
            return <SNPageNotFound />;
          }}
        />

        <Route component={SNPageNotFound} />
      </Switch>
      <SNProcessLoader loading={processLoading} />
      <MobileDrawer />
      {userInfo && userInfo.tos_accepted === false ? <TermsOfService /> : null}
    </>
  );
};

export default Root;
