import api, { url } from "../../app/appAPI";
import tenentApi, { url as tententURL } from "../../app/comAPI";
import { showErrorAlert } from "../../lib";
import store from "../../redux";
import { fetchCompanyInfo } from "../../redux/reducers/companyInfoReducer";
import {
  comSettingFetchingFail,
  comSettingFetchingInit,
  comSettingFetchingNotFound,
  comSettingFetchingSuccess,
} from "../../redux/reducers/comsettingsReducer";
import {
  invoiceFetchingFail,
  invoiceFetchingInit,
  invoiceFetchingNotFound,
  invoiceFetchingSuccess,
} from "../../redux/reducers/invoiceReducer";
import { fetchMyselfDataSuccess } from "../../redux/reducers/myselfReducer";
import {
  fetchPermissions,
  fetchPermissionsEnd,
  fetchPermissionsStart,
} from "../../redux/reducers/permissionReducer";
import {
  statsFetchingFail,
  statsFetchingStart,
  statsFetchingSuccess,
} from "../../redux/reducers/statsReducer";
import { fetchUnits } from "../../redux/reducers/unitsReducer";
import companyService from "../MyPage/Company/service";

export const fetchStatistics = () => {
  store.dispatch(statsFetchingStart());

  tenentApi.get(`${tententURL.STATISTICS}`, false).then((stats) => {
    if (stats.hasOwnProperty("asset"))
      store.dispatch(statsFetchingSuccess(stats));
    else {
      store.dispatch(statsFetchingFail());
    }
  });
};

export const fetchInvoiceSettings = () => {
  store.dispatch(invoiceFetchingInit());
  try {
    tenentApi.get(tententURL.SETTINGS_INVOICE, false).then((res) => {
      if (res && res.created_at) store.dispatch(invoiceFetchingSuccess(res));
      else {
        store.dispatch(invoiceFetchingNotFound());
      }
    });
  } catch (error) {
    store.dispatch(invoiceFetchingFail());
  }
};
export const fetchGeneralSettings = () => {
  store.dispatch(comSettingFetchingInit());
  try {
    tenentApi.get(tententURL.SETTINGS_GENERAL, false).then((res) => {
      if (res && res.created_at) store.dispatch(comSettingFetchingSuccess(res));
      else {
        store.dispatch(comSettingFetchingNotFound());
      }
    });
  } catch (error) {
    store.dispatch(comSettingFetchingFail());
  }
};
export const fetchTenentToken = async (params, postActions, failActions) => {
  api
    .postNoLoading(url.TENANT_TOKEN, params, false, true)
    .then((response) => {
      if (postActions) return postActions(response?.data || response);
    })
    .catch((error) => {
      showErrorAlert("my_companies.list.item_inactivated_by_admin_error_text");
      if (failActions) failActions();
    });
};

export const fetchPermission = (group) => {
  store.dispatch(fetchPermissionsStart());
  return tenentApi
    .get(`${tententURL.POLICY}group/${group}/statements/`)
    .then((response) => {
      store.dispatch(fetchPermissions(response));
      store.dispatch(fetchPermissionsEnd());
    });
};
export const fetchCurrentCompanyInfo = () => {
  companyService.getDetails().then((comData) => {
    store.dispatch(fetchCompanyInfo(comData));
  });
};

export function fetchMyselfData() {
  tenentApi.get(`${tententURL.MYSELF}`, false).then((res) => {
    if (res) store.dispatch(fetchMyselfDataSuccess(res));
  });
}

export function fetchUserUnits() {
  tenentApi.get(`${tententURL.UNIT}`, false).then((res) => {
    if (res) store.dispatch(fetchUnits(res));
  });
}
