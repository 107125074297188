import { Drawer } from "antd";
import { useState } from "react";
import MobileStoreButton from "react-mobile-store-button";
import Translate from "../../../../components/Translate";

const MobileDrawer = (props) => {
  const [visible, setVisible] = useState<boolean>(
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
  //   const [visible, setVisible] = useState<boolean>(true);
  const iOSUrl = "https://apps.apple.com/us/app/service-node/id1165985614";
  const android =
    "https://play.google.com/store/apps/details?id=se.strativ.servicenode&hl=en&gl=US";
  const renderIOS = () => {
    if (/iPhone/i.test(navigator.userAgent))
      return (
        <MobileStoreButton
          store="ios"
          url={iOSUrl}
          linkProps={{ title: "iOS Store Button" }}
          style={{ width: 146, height: 50 }}
          key="ios"
        />
      );
  };
  const renderAndroid = () => {
    if (
      /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    )
      return (
        <MobileStoreButton
          store="android"
          url={android}
          linkProps={{ title: "Android Button" }}
          style={{ width: 160, height: 70 }}
          key="android"
        />
      );
  };
  return (
    <Drawer
      placement="bottom"
      onClose={() => {
        setVisible(false);
      }}
      closable={false}
      title={<Translate i18nKey="common.label.mobile_install_app_message" />}
      visible={visible}
      contentWrapperStyle={{ height: 200 }}
      bodyStyle={{ display: "flex", justifyContent: "center" }}
    >
      {renderIOS()}
      {renderAndroid()}
    </Drawer>
  );
};

export default MobileDrawer;
