import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TOptions } from "i18next";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
interface TranslateProps extends TextProps {
  text?: any;
  i18nKey: any;
  style?: CSSProperties;
  count?: number;
  data?: string | TOptions;
}
function Translate(props: TranslateProps) {
  const { text, style, count, data = undefined, i18nKey, ...newProps } = props;
  const { t } = useTranslation();

  if (!text && !i18nKey) return null;
  if (text && typeof text !== "string") return text;
  if (i18nKey && typeof i18nKey !== "string") return text;

  return (
    <Text style={style} {...newProps}>
      {t(text || i18nKey, data)}
    </Text>
  );
}

export default Translate;
