import { createSlice } from "@reduxjs/toolkit";

interface InvoiceSettingsProps {
  company_bank_giro_number?: string;
  created_at?: string;
  created_by?: any;
  default_invoice_notes?: string;
  default_vat_percentage?: string;
  invoice_fee?: string;
  late_pay_interest_percentage?: string;
  next_invoice_number?: number;
  send_invoice_email_body?: string;
  send_invoice_email_from?: string;
  send_invoice_email_subject?: string;
  show_bank_giro_number?: boolean;
  show_logo?: boolean;
  show_vat_number?: boolean;
  updated_by?: any;
}

interface InitialProps {
  data: InvoiceSettingsProps;
  isLoading: boolean;
  isError: boolean;
  isNotFound: boolean;
}
const initialState: InitialProps = {
  data: {},
  isLoading: true,
  isError: false,
  isNotFound: false,
};
const invoiceSlice = createSlice({
  name: "invoiceTodos",
  initialState,
  reducers: {
    invoiceFetchingInit(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    invoiceFetchingSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        isNotFound: false,
        data: action.payload,
      };
    },
    invoiceFetchingNotFound(state) {
      return {
        ...state,
        data: {},
        isNotFound: true,
        isLoading: false,
      };
    },
    invoiceFetchingFail(state) {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    },
  },
});

const { actions, reducer } = invoiceSlice;

export const {
  invoiceFetchingFail,
  invoiceFetchingInit,
  invoiceFetchingNotFound,
  invoiceFetchingSuccess,
} = actions;

export default reducer;
