import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import axios from "../../../../utils/axios";
import "./index.scss";

interface SelectLangProps {
  className?: string;
}

const SelectLang: React.FC<SelectLangProps> = (props) => {
  const { i18n, t } = useTranslation();

  const radioStyle = {
    height: "41px",
    lineHeight: "41px",
  };
  return (
    <Radio.Group
      onChange={(e) => {
        axios.defaults.headers.common["Accept-Language"] =
          e.target.value || "sv";
        i18n.changeLanguage(e.target.value);
      }}
      value={i18n.language}
      className="sn-auth-change-language"
    >
      <Radio style={radioStyle} value="sv">
        {t("common.language_swedish")}
      </Radio>

      <Radio style={radioStyle} value="en">
        {t("common.language_english")}
      </Radio>
    </Radio.Group>
  );
};

export default SelectLang;
