import { CONTENT_TYPE } from "../../constant/app";
import { AssetStatusTag, CustomerStatusTag } from "../../lib";
import SNIcon from "../SN/SNIcon";

export default function ContentText({ type, content }) {
  const iconStyle = {
    marginRight: 10,
    color: "#9c9c9c",
  };

  switch (type) {
    case CONTENT_TYPE.asset.key:
      return (
        <span>
          <SNIcon icon="cubes" style={iconStyle} />
          {content && content.name}
          <AssetStatusTag status={content && content.status} />
        </span>
      );
    case CONTENT_TYPE.customer.key:
      return (
        <span>
          <SNIcon icon="briefcase" style={iconStyle} />
          {content && content.name}
          <CustomerStatusTag status={content && content.status} />
        </span>
      );
    case CONTENT_TYPE.company.key:
      return (
        <span>
          <SNIcon icon="building" style={iconStyle} />
          {content && content.name}
        </span>
      );
    case CONTENT_TYPE.workorder.key:
      return (
        <span>
          <SNIcon icon="file-invoice" style={iconStyle} />
          {content && content.name}
        </span>
      );
    case CONTENT_TYPE.manufacturer.key:
      return (
        <span>
          <SNIcon icon="industry-alt" style={iconStyle} />
          {content && content.name}
        </span>
      );
    case CONTENT_TYPE.supplier.key:
      return (
        <span>
          <SNIcon icon="warehouse" style={iconStyle} />
          {content && content.name}
          <AssetStatusTag status={content && content.status} />
        </span>
      );
    case CONTENT_TYPE.task.key:
      return (
        <span>
          <SNIcon icon="tasks" style={iconStyle} />
          {content && content.name}
        </span>
      );
    case CONTENT_TYPE.companyusermap.key:
      return (
        <span>
          <SNIcon icon="user" style={iconStyle} />
          {`${content.user.first_name} ${content.user.last_name}`}
        </span>
      );
    case CONTENT_TYPE.place.key:
      return (
        <span>
          <SNIcon icon="map" style={iconStyle} />
          {content && content.name}
        </span>
      );
    default:
      return (
        <span>
          <SNIcon icon="times-octagon" style={iconStyle} />-
        </span>
      );
  }
}
