import jwtDecode from "jwt-decode";
import appAPI from "../app/appAPI/api";
import { REFRESH_TOKEN } from "../app/appAPI/endpoints";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../config";
import { TOKEN_CONFIG } from "../config/settings";
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded: any = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(getRefreshToken, timeLeft);
};

const setSession = (accessToken?) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
    appAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken) as any;
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
  }
};

const getRefreshToken = async () => {
  try {
    const refresh_token = localStorage.getItem(REFRESH_TOKEN_NAME);
    const params = new FormData();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refresh_token);

    return appAPI
      .post(REFRESH_TOKEN, params, TOKEN_CONFIG)
      .then((response) => {
        let data = response.data;
        localStorage.setItem(ACCESS_TOKEN_NAME, data.access_token);
        localStorage.setItem(REFRESH_TOKEN_NAME, data.refresh_token);

        appAPI.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

        const { exp } = jwtDecode(data.access_token) as any;
        handleTokenExpired(exp);
        return data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    console.log("UNAUTHENTICATED", error);
    return false;
  }
};

export { isValidToken, setSession, getRefreshToken };
