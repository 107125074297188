import { Tag } from "antd";
import detailPagePlaceholderImage from "../assets/img/details_placeholder.jpg";
import serviceNodeIcon from "../assets/img/icon.png";
import placeholder_image from "../assets/img/img-placeholder.jpg";
import blackLogo from "../assets/img/logo-black.svg";
import whiteLogo from "../assets/img/logo-white.svg";
import logo from "../assets/img/logo.svg";
import clusterLogo from "../assets/img/m1.png";
import green_marker from "../assets/img/marker-green.png";
import red_marker from "../assets/img/marker-red.png";
import yellow_marker from "../assets/img/marker-yellow.png";
import marker from "../assets/img/marker.png";
import placeholder_user from "../assets/img/user-placeholder.jpg";
import SNBadge from "../components/SN/SNBadge";
import i18n from "../i18n";

export const IS_PRODUCTION = process.env.REACT_APP_ENV_NAME === "Production";
export const ENV_NAME = process.env.REACT_APP_ENV_NAME;
export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const BASE_ORIGIN = process.env.REACT_APP_BASE_ORIGIN_URL;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const APP_AUTH = `Basic ${process.env.REACT_APP_BASIC_AUTH}`;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const PUBLIC_PAGE_URL = process.env.REACT_APP_PUBLIC_PAGE_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const REGISTRATION_VERIFY_URL =
  process.env.REACT_APP_REGISTRATION_VERIFY_URL;

export const LOGO = logo;
export const SN_ICON = serviceNodeIcon;
export const LOGO_BLACK = blackLogo;
export const LOGO_WHITE = whiteLogo;
export const PLACEHOLDER = placeholder_image;
export const PLACEHOLDER_USER = placeholder_user;
export const PLACEHOLDER_DETAILS = detailPagePlaceholderImage;
export const YELLOW_MARKER = yellow_marker;
export const GREEN_MARKER = green_marker;
export const RED_MARKER = red_marker;
export const MARKER = marker;
export const CLUSTER_MARKER = clusterLogo;
export const MAP_CENTER = { lat: 59.32932349999999, lng: 18.0685808 };
export const PROVIDER_URL = "https://servicenode.se/";
// export const BASE_URL = 'http://bd.snode.se:8000';
export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
// export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const PROCESS_START = "PROCESS_START";
export const PROCESS_END = "PROCESS_END";
//Keep it single line string and no space after comma....
export const ACCEPTED_FILE_TYPES =
  "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,text/csv,application/pdf,image/jpeg,image/png,image/gif,image/bmp,image/svg+xml,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/epub+zip,application/x-mspublisher,text/html,application/rtf,image/vnd.djvu";
export const IMAGE_FILE_TYPES = "png,jpeg,gif,bmp,svg+xml";
export const MAX_FILE_SIZE = 5;
export const MAX_IMAGE_FILE_SIZE = 4;
export const MAX_CHECKLIST_ITEM = 100;
export const RECAPTCHA_SITE_KEY = "6LcvBSwUAAAAAJUmoT26-tPObrckZSSfIo8kBf2h";
export const MAX_CHAR_ELLIPSE = 100;
export const MAP_VIEW_LIMIT = 20000;
export const API_LIMIT = 1000;
export const DATE_FORMAT = "YYYY-MM-DD, HH:mm";
export const IMAGE_LIMIT = 20;

export const CHECKLIST_TYPE = function () {
  return [
    {
      value: 1,
      label: i18n.t("checklist.common.item_type.check_box"),
    },
    {
      value: 2,
      label: i18n.t("checklist.common.item_type.text_input"),
    },
    {
      value: 3,
      label: i18n.t("checklist.common.item_type.number_input"),
    },
  ];
};

export const FILE_ACCESS = function () {
  return [
    { value: 1, label: i18n.t("document.common.access.public") },
    { value: 2, label: i18n.t("document.common.access.private") },
    { value: 3, label: i18n.t("document.common.access.shared") },
  ];
};

export const CUSTOMER_TYPE = function () {
  return [
    { value: 1, label: i18n.t("customer.common.type.company") },
    { value: 2, label: i18n.t("customer.common.type.private") },
  ];
};

export const EMPLOYEE_TYPE = function () {
  return [
    { value: 2, label: i18n.t("user.common.group.manager") },
    { value: 3, label: i18n.t("user.common.group.service_person") },
  ];
};

export const DOCUMENT_TYPE = function () {
  return [
    { value: 1, label: i18n.t("Manual") },
    { value: 2, label: i18n.t("Blueprint") },
    { value: 3, label: i18n.t("Others") },
  ];
};

export const NODE_STATUS = function () {
  return [
    { value: 1, label: i18n.t("common.label.active") },
    { value: 2, label: i18n.t("common.label.inactive") },
    { value: 3, label: i18n.t("common.label.expired") },
  ];
};

export const ASSET_STATUS = function () {
  return [
    { value: 1, label: i18n.t("common.label.active") },
    { value: 2, label: i18n.t("common.label.inactive") },
    { value: 3, label: i18n.t("common.label.archived") },
  ];
};

export const ASSET_SHARING_STATUS = function () {
  return [
    { value: 1, label: i18n.t("Active") },
    { value: 2, label: i18n.t("Inactive") },
    { value: 3, label: i18n.t("Pending") },
  ];
};

export const EVENT_TYPE = function () {
  return [
    {
      value: 1,
      label: i18n.t("calendar.form.event_type_select_item_one_time_event"),
    },
    {
      value: 2,
      label: i18n.t("calendar.form.event_type_select_item_repetitive_event"),
    },
  ];
};
export const EVENT_REPEAT_UNIT = function () {
  return [
    {
      value: 1,
      label: i18n.t("calendar.form.repetitive_event_repeats_day"),
    },
    {
      value: 2,
      label: i18n.t("calendar.form.repetitive_event_repeats_week"),
    },
    {
      value: 3,
      label: i18n.t("calendar.form.repetitive_event_repeats_month"),
    },
    {
      value: 4,
      label: i18n.t("calendar.form.repetitive_event_repeats_year"),
    },
  ];
};

export const CONTACT_TYPES = function () {
  return [
    { value: 1, label: i18n.t("contact.common.type.primary") },
    { value: 2, label: i18n.t("contact.common.type.invoice") },
    { value: 3, label: i18n.t("contact.common.type.additional") },
    { value: 4, label: i18n.t("contact.common.type.foreman") },
    { value: 5, label: i18n.t("contact.common.type.purchaser") },
    { value: 6, label: i18n.t("contact.common.type.inspector") },
    { value: 7, label: i18n.t("contact.common.type.resident") },
    { value: 8, label: i18n.t("contact.common.type.tenant") },
    { value: 9, label: i18n.t("contact.common.type.plant_manager") },
    { value: 10, label: i18n.t("contact.common.type.admin") },
    { value: 11, label: i18n.t("contact.common.type.janitor") },
    {
      value: 12,
      label: i18n.t("contact.common.type.facilities_manager"),
    },
    { value: 13, label: i18n.t("contact.common.type.craftsman") },
    { value: 14, label: i18n.t("contact.common.type.technician") },
    { value: 15, label: i18n.t("contact.common.type.executive") },
    {
      value: 16,
      label: i18n.t("contact.common.type.representative_proxy"),
    },
    { value: 17, label: i18n.t("contact.common.type.site_manager") },
    {
      value: 18,
      label: i18n.t("contact.common.type.project_manager"),
    },
    {
      value: 19,
      label: i18n.t("contact.common.type.office_manager"),
    },
  ];
};

export const CONTACT_TYPES_LABEL = function (typeId) {
  const contact_types = {
    2: i18n.t("contact.common.type.invoice"),
    1: i18n.t("contact.common.type.primary"),
    3: i18n.t("contact.common.type.additional"),
    4: i18n.t("contact.common.type.foreman"),
    5: i18n.t("contact.common.type.purchaser"),
    6: i18n.t("contact.common.type.inspector"),
    7: i18n.t("contact.common.type.resident"),
    8: i18n.t("contact.common.type.tenant"),
    9: i18n.t("contact.common.type.plant_manager"),
    10: i18n.t("contact.common.type.admin"),
    11: i18n.t("contact.common.type.janitor"),
    12: i18n.t("contact.common.type.facilities_manager"),
    13: i18n.t("contact.common.type.craftsman"),
    14: i18n.t("contact.common.type.technician"),
    15: i18n.t("contact.common.type.executive"),
    16: i18n.t("contact.common.type.representative_proxy"),
    17: i18n.t("contact.common.type.site_manager"),
    18: i18n.t("contact.common.type.project_manager"),
    19: i18n.t("contact.common.type.office_manager"),
  };

  return contact_types[parseInt(typeId, 10)];
};

export const GET_LABEL_BY_ID = function (entityArray, id) {
  const entity = entityArray.filter((e) => e.value === id);

  return entity[0].label;
};

export const USER_STATUS = {
  request_sent: 1,
  request_accepted: 2,
  user_inactive: 3,
};

export const ASSET_STATUS_ID = {
  active: 1,
  inactive: 2,
  archived: 3,
};

export const ASSET_SHARING_STATUS_ID = {
  active: 1,
  inactive: 2,
  pending: 3,
};

export const ERROR_STATUS = {
  active: 1,
  resolved: 2,
  assigned: 3,
};

export const FORM_ATTRIBUTES = {
  text: "form_builder.form.component_header_label",
  line: "form_builder.form.component_line_label",
  input: "form_builder.form.component_text_field_label",
  textarea: "form_builder.form.component_text_area_label",
  number: "form_builder.form.component_number_field_label",
  decimal: "form_builder.form.component_deciaml_number_field_label",
  date: "form_builder.form.component_date_label",
  checkbox: "form_builder.form.component_checkbox_label",
  select: "form_builder.form.component_dropdown_label",
  radio: "form_builder.form.component_option_label",
};

export const ACTIVITY_ENTITY = {
  user: "snuser",
  company: "company",
};

export const CONTENT_TYPE = {
  asset: {
    key: "asset",
    name: "common.content_type.asset",
  },
  customer: {
    key: "customer",
    name: "common.label.customer",
  },
  companyusermap: {
    key: "companyusermap",
    name: "common.content_type.user",
  },
  advancedform: {
    key: "advancedform",
    name: "common.content_type.form",
  },
  entityform: {
    key: "entityform",
    name: "common.content_type.form_attachment",
  },
  error: {
    key: "error",
    name: "common.content_type.error",
  },
  snode: {
    key: "snode",
    name: "common.content_type.node",
  },
  service: {
    key: "service",
    name: "common.content_type.service",
  },
  visitors: {
    key: "visitors",
    name: "common.content_type.visitors",
  },
  servicepartner: {
    key: "servicepartner",
    name: "common.content_type.service_partner",
  },
  servicecustomer: {
    key: "servicecustomer",
    name: "common.content_type.service_customer",
  },
  assetsubtype: {
    key: "assetsubtype",
    name: "common.content_type.subtype",
  },
  model: {
    key: "model",
    name: "common.content_type.model",
  },
  contacts: {
    key: "contactattachment",
    name: "common.content_type.contact_attachment",
  },
  contactbook: {
    key: "contactbook",
    name: "common.content_type.contact_person",
  },
  contact: {
    key: "contact",
    name: "common.content_type.contact_person",
  },
  company: {
    key: "company",
    name: "common.content_type.company",
  },
  workorder: {
    key: "workorder",
    name: "common.content_type.workorder",
  },
  manufacturer: {
    key: "manufacturer",
    name: "common.content_type.mfc",
  },
  supplier: {
    key: "supplier",
    name: "common.content_type.supplier",
  },
  task: {
    key: "task",
    name: "common.content_type.task",
  },
  parts: {
    key: "parts",
    name: "common.content_type.parts",
  },
  timelog: {
    key: "timelog",
    name: "common.content_type.timelog",
  },
  serviceplan: {
    key: "serviceplan",
    name: "common.content_type.serviceplan",
  },
  scheduled_service: {
    key: "scheduled_service",
    name: "common.content_type.scheduled_service",
  },
  serviceplanrecur: {
    key: "scheduled_service",
    name: "common.content_type.scheduled_service",
  },
  checklist: {
    key: "checklist",
    name: "common.content_type.checklist",
  },
  invoice: {
    key: "invoice",
    name: "Invoice",
  },
  place: {
    key: "place",
    name: "common.content_type.place",
  },
  placetype: {
    key: "placetype",
    name: "common.content_type.placetype",
  },
};

export const TASK_STATUSES_LIST = function (
  taskStats = {
    total: 0,
    open: 0,
    started: 0,
    paused: 0,
    completed: 0,
    invoiced: 0,
    cancelled: 0,
  }
) {
  const {
    total,
    open,
    started,
    paused,
    completed,
    invoiced,
    cancelled,
  } = taskStats;
  return [
    {
      value: "all",
      label: (
        <div>
          {`${i18n.t("task_common_group_status_filter_all_task")}`}{" "}
          <SNBadge className="side-filter-badge" count={total} />
        </div>
      ),
    },
    {
      value: "1,2,3",
      text: i18n.t("task_common_group_status_filter_ongoing"),
      label: (
        <div>
          {`${i18n.t("task_common_group_status_filter_ongoing")}`}{" "}
          <SNBadge
            className="side-filter-badge"
            count={open + started + paused}
          />
        </div>
      ),
    },
    {
      value: "5",
      text: i18n.t("task_common_group_status_filter_completed"),
      label: (
        <div>
          {`${i18n.t("task_common_group_status_filter_completed")}`}{" "}
          <SNBadge className="side-filter-badge" count={completed} />
        </div>
      ),
    },
    {
      value: "4,6",
      text: i18n.t("task_common_group_status_filter_archived"),
      label: (
        <div>
          {`${i18n.t("task_common_group_status_filter_archived")}`}{" "}
          <SNBadge className="side-filter-badge" count={invoiced + cancelled} />
        </div>
      ),
    },
  ];
};

export const UNIT = function () {
  return [
    { value: "pcs", label: i18n.t("common.unit.pieces") },
    { value: "hrs", label: i18n.t("common.unit.hours") },
    { value: "min", label: i18n.t("common.unit.minutes") },
    { value: "g", label: i18n.t("common.unit.gram") },
    { value: "hg", label: i18n.t("common.unit.hectogram") },
    { value: "kg", label: i18n.t("common.unit.kilogram") },
    { value: "lbs", label: i18n.t("common.unit.pound") },
    { value: "ton", label: i18n.t("common.unit.ton") },
    { value: "mm", label: i18n.t("common.unit.millimeter") },
    { value: "cm", label: i18n.t("common.unit.centimeter") },
    { value: "dm", label: i18n.t("common.unit.decimeter") },
    { value: "m", label: i18n.t("common.unit.meter") },
    { value: "inch", label: i18n.t("common.unit.inch") },
    { value: "ft", label: i18n.t("common.unit.foot") },
    { value: "m2", label: i18n.t("common.unit.square_meter") },
    { value: "m3", label: i18n.t("common.unit.cubic_meter") },
    { value: "cl", label: i18n.t("common.unit.centiliter") },
    { value: "ml", label: i18n.t("common.unit.milliliter") },
    { value: "dl", label: i18n.t("common.unit.deciliter") },
    { value: "l", label: i18n.t("common.unit.liter") },
  ];
};

export const COST_TYPE = function () {
  return [
    { value: 1, label: i18n.t("expense_common_type_travel") },
    { value: 2, label: i18n.t("expense_common_type_admin") },
    { value: 3, label: i18n.t("expense_common_type_others") },
  ];
};

export const CONTRACT_TYPE = function () {
  return [
    {
      value: "customer",
      label: i18n.t("common.label.customer"),
    },
    {
      value: "company",
      label: i18n.t("contract_form_select_service_customer_label"),
    },
  ];
};

export const DottedLine = () => {
  return (
    <div style={{ marginBottom: "4px" }} disabled="true">
      ......................................................................................................................................................................
    </div>
  );
};
export const formStatus = (status) => {
  switch (status) {
    case "1":
      return `(${i18n.t("form_builder.form.status_list_item_open")})`;
    case 1:
      return `(${i18n.t("form_builder.form.status_list_item_open")})`;
    case "2":
      return `(${i18n.t("form_builder.form.status_list_item_close")})`;
    case 2:
      return `(${i18n.t("form_builder.form.status_list_item_close")})`;
    default:
      return "";
  }
};
export const assetSharingStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <Tag color="#5ac102">
          {i18n.t("asset.sharing.common_status_approved")}
        </Tag>
      );
    case 2:
      return (
        <Tag color="#ff9800">
          {i18n.t("asset.sharing.common_status_inactive")}
        </Tag>
      );
    case 3:
      return (
        <Tag color="#f50">
          {i18n.t("asset.sharing.common_status_approval_pending")}
        </Tag>
      );
    default:
      return "";
  }
};

export const buttonTextCalendar = () => {
  return {
    today: `${i18n.t("calendar.common.btn.today_label")}`,
    month: `${i18n.t("calendar.common.btn.month_label")}`,
    week: `${i18n.t("calendar.common.btn.week_label")}`,
    day: `${i18n.t("calendar.common.btn.day_label")}`,
    list: `${i18n.t("calendar.common.btn.day_label")}`,
    timeline: `${i18n.t("TimeLine")}`,
  };
};
export const validateMessages = () => {
  const typeTemplate =
    "'${label}'" +
    ` ${i18n.t("common.form.validation.is_not_a_valid")} ` +
    "'${type}'";
  return {
    required: i18n.t("common.form.validation.is_required"),
    max: "'${label}'" + ` ${i18n.t("is invalid.")}`,
    types: {
      string: typeTemplate,
      method: typeTemplate,
      array: typeTemplate,
      object: typeTemplate,
      number: i18n.t("common.form.validation.number_invalid_number"),
      date: i18n.t("common.form.validation.time_format"),
      boolean: typeTemplate,
      integer: typeTemplate,
      float: i18n.t("common.form.validation.decimal_invalid_decimal_num"),
      regexp: typeTemplate,
      email: i18n.t("common.form.validation.email_invalid_format"),
      url: i18n.t("common.form.validation.url_invalid_format"),
      hex: typeTemplate,
    },
  };
};
