import { createSlice } from "@reduxjs/toolkit";
import { checkTokenExpire } from "../../lib/token";

const initialState: {
  currentCompany: any;
  listTokens: any;
  isLoading: boolean;
} = {
  currentCompany: {},
  listTokens: {},
  isLoading: false,
};
const userSlice = createSlice({
  name: "companyAuthTodos",
  initialState,
  reducers: {
    addCompanyToken(state, action) {
      state.listTokens[action.payload.key] = action.payload.value;
    },
    setCurrentCompany(state, action) {
      state.currentCompany = action.payload;
    },
    deleteCompanyToken(state, action) {
      delete state.listTokens[action.payload];
      if (state.currentCompany?.company_uuid === action.payload)
        state.currentCompany = {};
    },
    refreshCompanyTokenStart(state) {
      state.isLoading = true;
    },
    refreshCompanyTokenSuccess(state, action) {
      state.listTokens[action.payload.key] = action.payload.value;
      state.isLoading = false;
    },
    refreshCompanyTokens(state) {
      Object.keys(state.listTokens).forEach((item) => {
        if (checkTokenExpire(state.listTokens[item].refresh_token))
          delete state.listTokens[item];
      });
    },
  },
});

const { actions, reducer } = userSlice;

export const {
  addCompanyToken,
  setCurrentCompany,
  refreshCompanyTokenStart,
  refreshCompanyTokenSuccess,
  deleteCompanyToken,
  refreshCompanyTokens,
} = actions;

export default reducer;
