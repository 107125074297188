import dayjs from "dayjs";
import "dayjs/locale/sv";
import duration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import DayJSTimezone from "dayjs/plugin/timezone";
import DayJSUtc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import store from "../redux";

var relativeTime = require("dayjs/plugin/relativeTime");
declare module "dayjs" {
  interface Dayjs {
    fromNow();
  }
}

export const mappingTimeFormat = { "24h": "HH:mm", "12h": "hh:mm a" };
export const mappingDateFormat = {
  "yyyy-mm-dd": "YYYY-MM-DD",
  "dd-mm-yyyy": "DD-MM-YYYY",
};

export const formatSystemDate = (datetime_field, format?) => {
  if (!datetime_field) return null;
  const comSetting = store.getState().comSetting.data;
  const time_format = mappingTimeFormat[comSetting?.time_format || "24h"];
  const date_format =
    mappingDateFormat[comSetting?.date_format || "yyyy-mm-dd"];
  const timezone = comSetting?.timezone || "Europe/Stockholm";
  return dayjs(datetime_field)
    .tz(`${timezone}`)
    .format(format || `${date_format} ${time_format}`);
};

dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
dayjs.extend(DayJSUtc);
dayjs.extend(DayJSTimezone);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(duration);

export default dayjs;
