import {
  FontAwesomeIconProps,
  FontAwesomeIcon as Icon,
} from "@fortawesome/react-fontawesome";
import "./index.scss";

interface SNIconProps extends FontAwesomeIconProps {}
const SNIcon = ({
  color,
  style,
  icon,
  name,
  className,
  ...props
}: SNIconProps) => {
  if (!icon) return null;

  if (typeof icon === "string")
    return (
      <Icon
        {...props}
        className={`sn-icon ${className || ""}`}
        icon={["far", icon]}
        aria-hidden="true"
        style={{ color: color, ...style }}
      />
    );
  if (icon[0] === "fak")
    return (
      <i
        className={`fak fa-${icon[1]} sn-fak-icon sn-icon ${className || ""}`}
        aria-hidden="true"
        style={{ color: color, ...style }}
      ></i>
    );
  return (
    <Icon
      {...props}
      className={`sn-icon ${className || ""}`}
      icon={icon}
      aria-hidden="true"
      style={{ color: color, ...style }}
    />
  );
};
export default SNIcon;
