import article from "./articles.svg";
import asset_type_subtype from "./asset-type-subtype.svg";
import asset from "./assets.svg";
import contact from "./contacts.svg";
import contract from "./contracts.svg";
import customer from "./customer.svg";
import document from "./documents.svg";
import errorPage from "./error-404-no-text.svg";
import error from "./error.svg";
import form from "./forms.svg";
import image from "./images.svg";
import invoice from "./invoice.svg";
import mfc_model from "./mfc-and-model.svg";
import node from "./QRtags.svg";
import recur from "./scheduled-services.svg";
import maintain from "./server-maintaince.svg";
import service_customer from "./service-customer.svg";
import service_partner from "./service-partner.svg";
import service_plan from "./service-plan.svg";
import service from "./service.svg";
import supplier from "./supplier.svg";
import task from "./tasks.svg";
import team from "./teams.svg";
import wo from "./workorder.svg";

const illustration = {
  asset,
  node,
  invoice,
  customer,
  wo,
  task,
  error,
  service,
  contact,
  contract,
  article,
  form,
  asset_type_subtype,
  mfc_model,
  service_partner,
  service_customer,
  supplier,
  service_plan,
  team,
  document,
  image,
  recur,
  maintain,
  errorPage,
};

export default illustration;
