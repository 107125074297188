import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    service_partner: {
      total: 0,
      approved: 0,
      pending: 0,
    },
    service_customer: {
      total: 0,
      approved: 0,
      pending: 0,
    },
    employee: {
      total: 0,
      active: 0,
      pending: 0,
      inactive: 0,
    },
    asset: {
      total: 0,
      active: 0,
      inactive: 0,
      archived: 0,
      owned: 0,
      shared: 0,
      shared_pending: 0,
    },
    asset_type: {
      total: 0,
      active: 0,
      inactive: 0,
      created_today: 0,
    },
    asset_subtype: {
      total: 0,
      active: 0,
      inactive: 0,
      created_today: 0,
    },
    parts: {
      total: 0,
      active: 0,
      inactive: 0,
      created_today: 0,
    },
    manufacturer: {
      total: 0,
      created_today: 0,
    },
    model: {
      total: 0,
      active: 0,
      inactive: 0,
      created_today: 0,
    },
    supplier: {
      total: 0,
      active: 0,
      inactive: 0,
      created_today: 0,
    },
    contract: {
      total: 0,
      draft: 0,
      active: 0,
      inactive: [],
      created_today: 0,
    },
    workorder: {
      total: 0,
      created_today: 0,
      open: 0,
      started: 0,
      paused: 0,
      completed: 0,
      completed_today: 0,
      invoiced: 0,
      cancelled: 0,
      due_today: 0,
      overdue: 0,
      not_assigned: 0,
      not_assigned_due_today: 0,
      not_assigned_overdue: 0,
    },
    task: {
      total: 0,
      created_today: 0,
      open: 0,
      started: 0,
      paused: 0,
      completed: 0,
      completed_today: 0,
      invoiced: 0,
      cancelled: 0,
      due_today: 0,
      overdue: 0,
      not_assigned: 0,
      not_assigned_due_today: 0,
      not_assigned_overdue: 0,
    },
    customer: {
      total: 0,
      active: 0,
      archived: 0,
    },
    node: {
      allocated: 0,
      installed: 0,
    },
    service: {
      total: 0,
      added_today: 0,
      added_this_week: 0,
      added_this_month: 0,
      last_service_id: 0,
    },
    error: {
      active: 0,
      assigned: 0,
      due_today: 0,
      last_error_id: 0,
      overdue: 0,
      reported_today: 0,
      resolved: 0,
      resolved_today: 0,
      total: 0,
      unassigned: 0,
      reported: 0,
    },
    form: {
      templates_total: 0,
      entity_total: 0,
      entity_created_today: 0,
      entity_open: 0,
      entity_close: 0,
      due_today: 0,
      overdue: 0,
      not_assigned: 0,
      not_assigned_due_today: 0,
      not_assigned_overdue: 0,
    },
    note: {
      total: 0,
      created_today: 0,
    },
    document: {
      total: 0,
      public: 0,
      private: 0,
      shared: 0,
      created_today: 0,
      space: 0,
    },
    image: {
      space: 0,
    },
    contact: {
      total: 0,
      created_today: 0,
    },
    serviceplan: {
      occurrence_cancelled: 0,
      occurrence_cancelled_today: 0,
      occurrence_closed: 0,
      occurrence_closed_today: 0,
      occurrence_created_today: 0,
      occurrence_scheduled: 0,
      occurrence_scheduled_due_today: 0,
      occurrence_scheduled_overdue: 0,
      occurrence_total: 0,
      serviceplan_created_today: 0,
      serviceplan_fixed_total: 0,
      serviceplan_recurring_total: 0,
      serviceplan_total: 0,
    },
    invoice: {
      created_today: 0,
      due_today: 0,
      invoice_cancelled: 0,
      invoice_date_today: 0,
      invoice_draft: 0,
      invoice_draft_amount: { total: 0, min: 0, max: 0, avg: 0 },
      total: 0,
      invoice_issued: 0,
      invoice_issued_amount: { total: 0, min: 0, max: 0, avg: 0 },
      invoice_paid: 0,
      invoice_paid_today: 0,
      overdue: 0,
    },
    team: { total: 0, active: 0, inactive: 0 },
    checklist: {
      total: 0,
    },
    integration: [],
  },
  isLoading: false,
  isNotFound: true,
};
const statsSlice = createSlice({
  name: "statsTodos",
  initialState,
  reducers: {
    statsFetchingStart(state) {
      state.isLoading = true;
    },
    statsFetchingSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        isNotFound: false,
        data: action.payload,
      };
    },
    statsFetchingFail(state) {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isNotFound: false,
      };
    },
  },
});

const { actions, reducer } = statsSlice;

export const {
  statsFetchingFail,
  statsFetchingStart,

  statsFetchingSuccess,
} = actions;

export default reducer;
