import { WidgetInstance } from "friendly-challenge";
import { CSSProperties, FC, useEffect, useRef } from "react";
import { FRIENDLY_CAPTCHA_KEY } from "../../config";
import "./index.scss";
import { onCheckSolution } from "./service";

interface FriendlyCaptchaProps {
  style?: CSSProperties;
  onDone?: (solution?) => void;
}
const FriendlyCaptcha: FC<FriendlyCaptchaProps> = ({ style, onDone }) => {
  const container = useRef();
  const widget = useRef<any>();

  const doneCallback = (solution) => {
    onCheckSolution(solution)
      .then((res) => {
        if (res) {
          if (onDone) onDone(solution);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const errorCallback = (err) => {
    console.log("There was an error when trying to solve the Captcha.");
    console.log(err);
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "focus",
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      });
    }

    return () => {
      if (widget.current !== undefined) widget.current.reset();
    };
  }, [container]);

  return (
    <div
      ref={container}
      className="frc-captcha sn-captcha"
      data-sitekey={FRIENDLY_CAPTCHA_KEY}
      style={style}
    />
  );
};

export default FriendlyCaptcha;
