import { PageLoading } from "@ant-design/pro-layout";
import { ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import Login from "../screens/auth/Login";

// ----------------------------------------------------------------------

interface AuthGuardProps {
  children: ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <PageLoading />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return <>{children}</>;
}
