import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  company_uuid: null,
  owner: {
    id: null,
    username: null,
    first_name: null,
    last_name: null,
    image: null,
    contact: {
      email: null,
      phone1: null,
      phone2: null,
    },
    is_active: true,
  },
  company_id: null,
  company_name: null,
  website: null,
  logo: null,
  description: null,
  address: {
    id: null,
    address: null,
    address2: null,
    post: null,
    city: null,
    country: null,
    lat: null,
    lng: null,
  },
  invoice_ref: null,
  invoice_email: null,
  invoice_address: {
    id: null,
    address: null,
    address2: null,
    post: null,
    city: null,
    country: null,
    lat: null,
    lng: null,
  },
  is_active: true,
  allow_partnership_request: true,
  company_country: null,
  industry_type: null,
  vat_reg_number: null,
  company_phone: null,
  company_email: null,
  updated_by: {
    id: null,
    username: null,
    first_name: null,
    last_name: null,
    image: null,
  },
  updated_at: null,
  created_by: null,
  created_at: null,
  company_user_group: null,
  contacts: [
    {
      id: null,
      contact_person: {
        id: null,
        company: null,
        first_name: null,
        last_name: null,
        job_title: null,
        contact: {
          email: null,
          phone1: null,
          phone2: null,
        },
        address: null,
        note: null,
      },
      content_type: null,
      object_id: null,
      type: null,
      description: null,
      order: null,
    },
  ],
};
const companyInfoSlice = createSlice({
  name: "companyInfoTodos",
  initialState,
  reducers: {
    fetchCompanyInfo(state, action) {
      return {
        ...action.payload,
      };
    },
  },
});

const { actions, reducer } = companyInfoSlice;

export const { fetchCompanyInfo } = actions;

export default reducer;
