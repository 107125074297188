import { CloseCircleFilled } from "@ant-design/icons";
import { notification } from "antd";
import { createContext, ReactNode, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import appAxios from "../app/appAPI/api";
import appURL from "../app/appAPI/urls";
import {
  ACCESS_TOKEN_NAME,
  COMPANY_ACCESS_TOKEN_NAME,
  COMPANY_REFRESH_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
} from "../config";
import { TOKEN_CONFIG } from "../config/settings";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getUserProfile } from "../screens/Root/service";
import dayjs from "../utils/dayjs";
// utils
import appAPI, { url } from "../app/appAPI";
import { CLIENT_ID, CLIENT_SECRET } from "../constant/app";
import { getRefreshToken, isValidToken, setSession } from "../utils/appJWT";
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },
  LOGIN: (state) => {
    return {
      ...state,
      isAuthenticated: true,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
  }),
  REGISTER: (state) => {
    return {
      ...state,
      isAuthenticated: true,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  login?: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
}
const AuthContext = createContext<IAuthContext>({
  ...initialState,
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

interface AuthProviderProps {
  children: ReactNode;
}
function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const companies = useAppSelector((state) => state.cum.companies);
  const history = useHistory();
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

        if (isValidToken(accessToken)) {
          setSession(accessToken);

          const res = await getUserProfile();

          if (res?.hasOwnProperty("id")) {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
              },
            });
            throw new Error("Unauthenticated");
          }
        } else {
          companies?.forEach((item) => {
            localStorage.removeItem(
              COMPANY_ACCESS_TOKEN_NAME + item.company_uuid
            );
            localStorage.removeItem(
              COMPANY_REFRESH_TOKEN_NAME + item.company_uuid
            );
          });

          const refreshRes = await getRefreshToken();

          if (!refreshRes) throw new Error("Unauthenticated");
          else {
            const res = await getUserProfile();

            if (res?.hasOwnProperty("id")) {
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: true,
                },
              });
            } else {
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: false,
                },
              });
              throw new Error("Unauthenticated");
            }
          }
        }
      } catch (err) {
        localStorage.clear();
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const params = new FormData();
      params.append("username", username);
      params.append("password", password);
      params.append("grant_type", "password");

      const response = await appAxios.post(appURL.LOGIN, params, TOKEN_CONFIG);

      const { access_token, refresh_token } = response.data;

      setSession(access_token);
      localStorage.setItem(REFRESH_TOKEN_NAME, refresh_token);

      dispatch({
        type: "LOGIN",
      });

      history.push("/mypage");
      return true;
    } catch (e) {
      let extra = "";
      if (e.response.data?.error_description?.cooloff_timedelta) {
        extra = `${dayjs
          .duration(e.response.data?.error_description?.cooloff_timedelta)
          .minutes()}`;
      }
      notification["error"]({
        message: t("common.form.error_title"),
        top: 64,
        description: t(`login.form.${e.response.data.error}`, {
          extra,
        }),
        icon: <CloseCircleFilled style={{ color: "#f50" }} />,
      });

      return false;
    }
  };

  const logout = async () => {
    appAPI
      .post(
        url.REVOKE_TOKEN,
        {
          token: localStorage.getItem(ACCESS_TOKEN_NAME),
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
        },
        true
      )
      .then((response) => {
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
        appDispatch({
          type: "LOGOUT",
        });
        history.push("/login");
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
