import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";
import { FC, ReactNode } from "react";
import Translate from "../../Translate";
import "./index.scss";
interface SNTooltipProps extends Omit<TooltipProps, "snTooltip"> {
  text: string | ReactNode;
  noTranslate?: boolean;
}

const SNTooltip: FC<SNTooltipProps> = (props) => {
  const { text, noTranslate, className, ...rest } = props;
  return (
    <Tooltip
      {...rest}
      title={typeof text === "string" ? <Translate i18nKey={text} /> : text}
      className={`${className || ""} sn-tooltip`}
    />
  );
};

export default SNTooltip;
