import moment from "moment";
import { UserAccessTokenData } from "../screens/auth/data.t";
import { CompanyData } from "../screens/MyPage/MyCompanies/data.t";

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export function checkTokenExpire(token) {
  if (!token) return true;
  const tokenData: UserAccessTokenData = parseJwt(token);

  return moment().unix() >= tokenData.exp;
}

export function getCurrentCommpany(companies: CompanyData[], uuid: string) {
  const current = companies.find((item) => item.company_uuid === uuid);

  return current;
}
