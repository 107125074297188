function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_COMPANY = "/company";
export const PATH_AUTH = {
  login: "/login",
  register: "/register",
  verify: "/verify",
  resetPassword: "/reset-password",
  newPassword: "/new-password",
};

export const PATH_MYPAGE = {
  root: "/mypage",
};

export const PATH_COMPANY = {
  root: (uuid) => path(ROOTS_COMPANY, `/${uuid}/`),
};
