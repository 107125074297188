import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import api, { url } from "../../../app/appAPI";
import regImage from "../../../assets/img/SVG/create-account.svg";
import SNButton from "../../../components/SN/SNButton";
import SNLoader from "../../../components/SN/SNLoader";
import Translate from "../../../components/Translate";
import { login_url } from "../../../constant/routeURL";
import { RESEND_ACTIVATION_MAIL } from "../../Root/rootUrls";
import AuthLayout from "../components/AuthLayout";

function RegVerify(props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uidb64 = params.get("uidb64");
  const token = params.get("token");

  useEffect(() => {
    setLoading(true);
    api
      .get(`${url.REGISTRATION}${uidb64}/${token}/`, true, null, true)
      .then((res) => {
        setLoading(false);
        if (res && res.status === 200) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      })
      .catch((e) => {
        setIsValid(false);
      });
  }, [uidb64, token]);

  const _renderSuccessMessage = () => {
    if (isValid)
      return (
        <div>
          <div>
            <Translate
              i18nKey="login.verification.success_header"
              type="success"
              strong
              style={{ fontSize: 24, marginBottom: 12, display: "block" }}
            />

            <Translate
              i18nKey="login.verification.success_text"
              type="success"
              style={{ fontSize: 16, marginBottom: 12, display: "block" }}
            />

            <Translate
              i18nKey="login.verification.success_instruct"
              type="success"
              style={{ fontSize: 16, marginBottom: 12, display: "block" }}
            />
          </div>

          <SNButton
            type="primary"
            title="registration.form.success_link_login"
            link={login_url}
            block
          />
        </div>
      );
    return (
      <div className="text-center">
        <Translate
          i18nKey="login.verification.fail_text"
          type="danger"
          strong
          style={{ fontSize: 20, marginBottom: 12, display: "block" }}
        />
        <div className="sn-space">&nbsp;</div>
        <SNButton
          type="primary"
          title="registration.form.success_link_login"
          link={login_url}
          block
        />
      </div>
    );
  };

  return (
    <AuthLayout
      title="login.verification.title"
      img={regImage}
      footer={
        !loading &&
        !isValid && [
          <SNButton
            type="link"
            title="registration.form.success_link_activation_email_text"
            link={RESEND_ACTIVATION_MAIL}
          />,
        ]
      }
    >
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SNLoader loaderStyle="dot" />
        </div>
      ) : (
        _renderSuccessMessage()
      )}
    </AuthLayout>
  );
}

export default RegVerify;
