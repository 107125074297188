import { Alert, Form, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import api, { url } from "../../../app/appAPI";
import forgetImage from "../../../assets/img/SVG/password.svg";
import FriendlyCaptcha from "../../../components/FriendlyCaptcha";
import SNButton from "../../../components/SN/SNButton";
import SNFormItem from "../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../components/SN/SNForm/SNInput";
import SNIcon from "../../../components/SN/SNIcon";
import Translate from "../../../components/Translate";
import { BASE_ORIGIN } from "../../../constant/app";
import { login_url } from "../../../constant/routeURL";
import { RESEND_ACTIVATION_MAIL } from "../../Root/rootUrls";
import AuthLayout from "../components/AuthLayout";

let KEY = 0;
function ForgetPassword(props) {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isDone, setIsDone] = useState<boolean>(false);

  const [isFailed, setIsFailed] = useState<boolean>(false);
  const onFinish = (data) => {
    setLoading(true);
    data.url = `${BASE_ORIGIN}reset-password/`;

    api.post(url.RESET_PASSWORD, data).then((response) => {
      setLoading(false);
      KEY += 1;
      setIsDone(false);
      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        setIsFailed(true);
      }
    });
  };
  const renderform = () => {
    return (
      <Form size="large" form={form} onFinish={onFinish}>
        <p>
          <Translate i18nKey="login.forget_password_form.text" />
        </p>
        <br />
        <Space direction="vertical" style={{ width: "100%" }}>
          <SNFormItem name="email" rules={[{ type: "email" }]}>
            <SNInput
              type="email"
              prefix={<SNIcon icon="envelope-open" />}
              placeholder="login.forget_password_form.email_placeholder"
            />
          </SNFormItem>
          {isFailed && (
            <Alert
              message={t("login.forget_password_form.fail_alert_title")}
              description={
                <div
                  className="text-description"
                  style={{ textAlign: "start" }}
                >
                  {t("login.forget_password_form.fail_alert_content")}
                </div>
              }
              type="error"
            />
          )}
          <FriendlyCaptcha
            onDone={() => {
              setIsDone(true);
            }}
            key={KEY}
          />
          <SNButton
            type="primary"
            title="login.forget_password_form.btn_send_label"
            style={{ height: 45 }}
            block
            onClick={form.submit}
            loading={loading}
            key="send"
            disabled={!isDone}
          />
          <SNButton
            title="login.forget_password_form.success_link_login"
            link={login_url}
            type="link"
            key="backToLogin"
          />
          <SNButton
            title="login.forget_password_form.link_activation_email_text"
            link={RESEND_ACTIVATION_MAIL}
            type="link"
            key="resend"
          />
        </Space>
      </Form>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className="reg-success-page">
        <div className="sn-space">&nbsp;</div>
        <h1>
          <Translate i18nKey="login.forget_password_form.success_text" />
        </h1>
        <SNButton
          type="primary"
          title="registration.form.success_link_login"
          link={login_url}
          block
        />
      </div>
    );
  };

  return (
    <AuthLayout title="login.forget_password_form.title" img={forgetImage}>
      {!isSuccess ? renderform() : renderSuccessMessage()}
    </AuthLayout>
  );
}

export default ForgetPassword;
