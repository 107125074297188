import i18n from "../i18n";
import { errorAlert, serverAlert } from "../lib/alertFunctions";

export function handleServerErrors(
  error,
  showServerMessage = true,
  fixedMessage = ""
) {
  if (error.response)
    switch (error.response.status) {
      case 400:
        if (showServerMessage) {
          let errorItem = error.response.data;
          serverAlert(generateBadRequest(errorItem), fixedMessage);
          return error;
        }

        return error;

      case 403:
        if (showServerMessage)
          serverAlert(i18n.t("policy.permission.error_message"));
        return error.response;

      case 500:
        if (showServerMessage)
          serverAlert("Something went wrong! please try again in 30 seconds!");
        return error.response;

      default:
        if (showServerMessage) errorAlert("common.api_error_text");
        return error;
    }
  return null;
}

/*400 or Bad Request Message Generator from API Error Response*/
function generateBadRequest(errorItems) {
  let totalErrors = [];
  if (typeof errorItems === "string") {
    totalErrors.push(errorItems);
  } else {
    const error_object_keys = Object.keys(errorItems);

    error_object_keys.forEach(function (key) {
      const objError = errorItems[key];
      // IF mess is array
      if (Array.isArray(objError)) {
        objError.map((error) =>
          totalErrors.push({ title: key, message: error.message })
        );
      } else {
        // Mess is object
        Object.keys(objError).forEach((obj) => {
          if (typeof objError[obj] === "object")
            totalErrors.push({ message: objError[obj].message });

          if (Array.isArray(objError[obj]))
            objError[obj].map((objE) =>
              totalErrors.push({ title: key, message: objE.message })
            );
        });

        if (objError.message && typeof objError.message === "string")
          totalErrors.push({ message: objError.message });
      }
    });
  }

  return totalErrors;
}
