import React, {Component} from 'react';
import {SN_ICON} from "../../constant/app";

class SeviceNodeLoader extends Component {
    render() {
        const { style, className, imgStyle } = this.props;

        return (
            <div style={style} className={className}>
                <img src={SN_ICON} alt="" className="image-flash center-block" style={{ width: 100, ...imgStyle }}/>
            </div>
        );
    }
}

export default SeviceNodeLoader;