import React from "react";

export function InputLabel(label, required, rightButton, rightButtonCallBack) {
  return <label className={required ? "field-required" : ""}>{label}</label>;
}

export function InputHelpText(helpText) {
  return (
    <span className="help-block">
      <small>{helpText}</small>
    </span>
  );
}

export function formatMapSearchResult(search_result) {
  let formatted_address = {
    address: "",
    city: "",
    country: "",
    post: "",
    lat: 1,
    lng: 1,
  };

  if (
    search_result.length > 0 &&
    search_result[0].hasOwnProperty("address_components")
  ) {
    let places = search_result[0]["address_components"];

    places.map((place) => {
      switch (place.types[0]) {
        case "street_number":
          formatted_address.address = place.long_name;
          break;

        case "route":
          formatted_address.address =
            place.long_name + " " + formatted_address.address;
          break;

        case "locality":
          formatted_address.city = place.long_name;
          break;

        case "postal_town":
          formatted_address.city = place.long_name;
          break;

        case "country":
          formatted_address.country = place.long_name;
          break;

        case "postal_code":
          formatted_address.post = place.long_name;
          break;

        default:
          break;
      }

      return null;
    });
  } else {
    return formatted_address;
  }

  return formatted_address;
}

//Formsy custom validation, to give error if value contains empty string.
export function isNotEmpty(values, value) {
  return value !== "";
}
