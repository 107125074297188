import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  user: {
    id: null,
    username: null,
    first_name: null,
    last_name: null,
    image: null,
    contact: {
      email: null,
      phone1: null,
      phone2: null,
    },
    is_active: true,
  },
  email: null,
  contact: {
    email: null,
    phone1: null,
    phone2: null,
  },
  group: null,
  designation: null,
  employee_number: null,
  status: null,
  is_rejected: false,
};
const myselfSlice = createSlice({
  name: "userTodos",
  initialState,
  reducers: {
    fetchMyselfDataSuccess(state, action) {
      return {
        ...action.payload,
      };
    },
  },
});

const { actions, reducer } = myselfSlice;

export const { fetchMyselfDataSuccess } = actions;

export default reducer;
