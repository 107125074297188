// import "@ant-design/pro-form/dist/form.css";
// import "@ant-design/pro-layout/dist/layout.css";
// import "@ant-design/pro-table/dist/table.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { ConfigProvider } from "antd";
import en_GB from "antd/es/locale/en_GB";
import svSE from "antd/es/locale/sv_SE";
import axios from "axios";
import "braft-editor/dist/index.css";
import moment from "moment";
import "moment/locale/en-gb";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "react-s-alert/dist/s-alert-default.css";
import { RecoilRoot } from "recoil";
import appAxios from "./app/appAPI/api";
import comAxios from "./app/comAPI/api";
import { LoaderPage } from "./components/Loader";
import { BASE_API_URL, ENV_NAME, validateMessages } from "./constant/app";
import WarningAffix from "./layouts/components/WarningAffix";
import { useAppSelector } from "./redux/hooks";
import { processEnd } from "./redux/reducers/appReducer";
import Root from "./screens/Root";
import dayjs from "./utils/dayjs";

const FAS: any = fas;
const FAR: any = far;
const FAL: any = fal;
const FAB: any = fab;
library.add(FAS, FAR, FAL, FAB);

axios.defaults.baseURL = BASE_API_URL;

declare module "recoil" {
  interface RecoilRootProps {
    children: any;
  }
}
declare module "react-google-maps" {
  interface GoogleMapProps {
    children: any;
  }
}
const App = (props) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    const type = ENV_NAME;

    const titleList = {
      test: "SN | Test",
      Development: "Service Node | dev",
      Staging: "Service Node | staging",
      Production: "Service Node",
    };
    document.title = titleList[type];

    dispatch(processEnd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.language) {
      moment.locale(user.language);
      dayjs.locale(user.language);
      i18n.changeLanguage(user.language);
      comAxios.defaults.headers.common["Accept-Language"] = user.language;
      appAxios.defaults.headers.common["Accept-Language"] = user.language;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Suspense fallback={<LoaderPage pastDelay={true} />}>
      <ConfigProvider
        locale={user?.language === "en" ? en_GB : svSE}
        form={{ validateMessages: validateMessages() }}
      >
        <RecoilRoot>
          <WarningAffix>
            <Root />
          </WarningAffix>
        </RecoilRoot>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
