import { Result } from "antd";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import illustration from "../../../assets/img/SVG/illustration";
import Translate from "../../Translate";
import SNAvatar from "../SNAvatar";
import SNButton from "../SNButton";
import "./index.scss";

function SNPageNotFound() {
  const history = useHistory();
  return (
    <div>
      <Result
        icon={<SNAvatar size={400} src={illustration.error} />}
        title={<Translate i18nKey="common.not_found_page.title" />}
        extra={[
          <SNButton
            title="common.not_found_page.btn_back_label"
            onClick={history.goBack}
            key="goBack"
            size="large"
            type="link"
          />,
        ]}
      >
        <div className="page-not-found-extra">
          <Translate i18nKey="common.not_found_page.contact_title" />
          <br />
          <Trans i18nKey="common.not_found_page.contact_info">
            Call us at
            <a href="tel:+461234567">+461234567</a>
            or e-mail us at
            <a href="mailto:support@servicenode.se">support@servicenode.se</a>
          </Trans>
        </div>
      </Result>
    </div>
  );
}

export default SNPageNotFound;
