/* eslint-disable import/no-anonymous-default-export */
import { AxiosRequestConfig } from "axios";
import "url-search-params-polyfill";
import store from "../../redux";
import { processEnd, processStart } from "../../redux/reducers/appReducer";
import {
  deleteCompanyToken,
  refreshCompanyTokenSuccess,
} from "../../redux/reducers/companyAuth";
import { handleServerErrors } from "../../utils/serverAlert";
import axios from "./api";
import endpoint, { REFRESH_TENANT_TOKEN } from "./endpoints";

export interface TokenData {
  access_token: string;
  refresh_token: string;
}
export const getCompanyUUID = (pathname?) => {
  const queryString = pathname || window.location.pathname;
  var id = queryString.split("/")[1];
  var pattern = new RegExp(
    "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$",
    "i"
  );

  if (pattern.test(id) === true) {
    return id;
  }
  return "";
};
export const getCompanyToken = () => {
  var companyId = getCompanyUUID();

  if (store.getState().comAuth.listTokens)
    return store.getState().comAuth.listTokens[companyId];
  return null;
};

function requestForRefreshToken() {
  return axios
    .post(REFRESH_TENANT_TOKEN, {
      refresh_token: getCompanyToken()?.refresh_token,
    })
    .then((response) => {
      let data = response.data;
      store.dispatch(
        refreshCompanyTokenSuccess({ key: getCompanyUUID(), value: data })
      );
      return data;
    })
    .catch((error) => {
      store.dispatch(deleteCompanyToken(getCompanyUUID()));
      return {};
    });
}

function get(url, data = {}, cancelToken?) {
  return axios
    .get(url, { cancelToken: cancelToken })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        window.location.pathname = "/maintenance";
      }
      return handleServerErrors(error, false);
    });
}

function get_blob(url, cancelToken?) {
  return axios
    .get(url, { cancelToken: cancelToken , responseType: 'blob' })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        window.location.pathname = "/maintenance";
      }
      return handleServerErrors(error, false);
    });
}

function patch(url, params?, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .patch(url, params)
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}
function patchNoLoading(
  url,
  params?,
  isFormData = false,
  showServerMessage = true
) {
  if (isFormData) params = processFormData(params);

  return axios
    .patch(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function post(url, params, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());

  if (isFormData) params = processFormData(params);

  return axios
    .post(url, params)
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}
interface PostWithConfigProps {
  url: string;
  params?: object;
  isFormData?: boolean;
  showServerMessage?: boolean;
  token?: TokenData;
  config?: AxiosRequestConfig;
  hideLoader?: boolean;
}
function POST({
  url,
  params,
  isFormData = false,
  showServerMessage = true,
  token,
  config = {},
  hideLoader,
}: PostWithConfigProps) {
  if (!hideLoader) store.dispatch(processStart());

  if (isFormData) params = processFormData(params);

  return axios
    .post(url, params, { ...config })
    .then((response) => {
      if (!hideLoader) store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      if (!hideLoader) store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}
function postNoLoading(
  url,
  params,
  isFormData = false,
  showServerMessage = true,
  token?: TokenData
) {
  if (isFormData) params = processFormData(params);

  return axios
    .post(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function put(url, params, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .put(url, params)
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}

function del(url, params?, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .delete(url, { data: params })
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}

function delNoLoading(
  url,
  params?,
  isFormData = false,
  showServerMessage = true
) {
  if (isFormData) params = processFormData(params);

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function processFormData(data) {
  let formData: any = new FormData();
  for (const key of Object.keys(data)) {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      (data[key].hasOwnProperty("fileName") ||
        data[key].hasOwnProperty("name") ||
        data[key].hasOwnProperty("file") ||
        data[key].hasOwnProperty("attachment"))
    ) {
      formData.append(
        key,
        data[key].value,
        data[key].fileName || data[key].name
      );
    } else if (key === "files") {
      const files = data["files"];
      files?.forEach((item, key) => formData.append("files", item));
    } else if (data[key] !== null && data[key] !== undefined) {
      if (Array.isArray(data[key])) {
        for (var i = 0; i < data[key].length; i++) {
          formData.append(`${key}[${i}]`, data[key][i]);
        }
      } else formData.append(key, data[key]);
    }
  }

  if (data.hasOwnProperty("blobFileName")) {
    const key = data.blobFileName.key;

    formData.append(key, data[key], data.blobFileName.name);
  }

  return formData;
}

export const url = endpoint.url;
export default {
  get,
  get_blob,
  patch,
  post,
  postNoLoading,
  put,
  del,
  delNoLoading,
  requestForRefreshToken,
  POST,
  patchNoLoading,
};
