export const formatNameAvatar = (name, num = 2) => {
  if (!name) return "";
  var matches = name.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches?.join(""); // JSON
  return acronym?.slice(0, num);
};

export const formatName = (first_name, last_name, emptyReturn) => {
  if (!first_name && !last_name) return emptyReturn || "- -";
  if (last_name) return `${first_name || ""} ${last_name || ""}`;
  return first_name;
};
