const API_VERSION = process.env.REACT_APP_API_VERSION;
const base = `/${API_VERSION}`;
export const REFRESH_TOKEN = "/o/token/";
export const REVOKE_TOKEN = "/o/revoke_token/";
export const TENANT_TOKEN = "/v4/tenant/token/";
export const REGISTRATION = `${base}/auth/registration/`;
export const MY_PROFILE = `${base}/user/profile/`;
export const COMPANY = `${base}/company/`;
export const MY_COMPANIES = `${base}/company/users/`;
export const MY_PROFILE_COMPANY = `${base}/company/profiles/`;
export const REGISTRATION_RESEND = `${base}/auth/registration/resend/`;
export const RESET_PASSWORD = `${base}/auth/password-reset/`;
export const NOTIFICATION = `${base}/notification/`;
export const NOTIFICATION_SETTINGS = `${base}/activity/event_type/settings/`;
export const EMPLOYEE = `${base}/company/users/`;
export const USER_PROFILE = `${base}/user/profile/`;
export const CHANGE_PASSWORD = `${base}/auth/password-change/`;
export const NEWSFEED = `${base}/newsfeed/`;

export default {
  url: {
    CHANGE_PASSWORD,
    REFRESH_TOKEN,
    REGISTRATION,
    MY_PROFILE,
    COMPANY,
    MY_COMPANIES,
    TENANT_TOKEN,
    REVOKE_TOKEN,
    MY_PROFILE_COMPANY,
    REGISTRATION_RESEND,
    RESET_PASSWORD,
    NOTIFICATION,
    EMPLOYEE,
    USER_PROFILE,
    NEWSFEED,
    NOTIFICATION_SETTINGS,
  },
};
