import { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

function LinkGenerator(props) {
  const { id, entity, obj, company } = props;
  let initial = company ? `/${company}` : `/mypage`;

  const entityLinks = {
    asset: `/asset/${id}/`,
    error: `/error/${id}/`,
    service: `/service/${id}/`,
    snode: `/node/${id}/`,
    customer: `/customer/${id}/`,
    serviceplan: `/recur/${id}/`,
    task: `/task/${id}/`,
    supplier: `/supplier/${id}/`,
    manufacturer: `/mfc-model/mfc/${id}/`,
    parts: `/article/${id}/`,
    place: `/place/?id=${id}`,
    workorder: `/workorder/${id}/`,
  };

  if (entityLinks[entity]) {
    return <Link to={`${initial}${entityLinks[entity]}`}>{obj}</Link>;
  } else {
    return <span>{obj}</span>;
  }
}
const objectStrings = {
  error: "common.content_type.error",
};
class ActivityTextWithLink extends Component {
  render() {
    const {
      actor,
      actor_entity,
      actor_object_id,
      verb,
      action_object,
      object,
      target,
      target_entity,
      target_object_id,
      action_object_entity,
      action_object_object_id,
    } = this.props.activity;
    const { company } = this.props;

    return (
      <span style={{ fontWeight: "normal" }}>
        <LinkGenerator
          obj={actor}
          id={actor_object_id}
          entity={actor_entity}
          company={company}
        />
        &nbsp;
        <span>{i18n.t(verb)}</span>&nbsp;
        <LinkGenerator
          obj={action_object}
          id={action_object_object_id}
          entity={action_object_entity}
          company={company}
        />
        &nbsp;
        <span>
          {object !== "" ? i18n.t(objectStrings[object] || object) : ""}
        </span>
        &nbsp;
        <LinkGenerator
          obj={target}
          id={target_object_id}
          entity={target_entity}
          company={company}
        />
      </span>
    );
  }
}

export default ActivityTextWithLink;
