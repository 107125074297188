import React from 'react';
import {CSSTransition} from 'react-transition-group';
import './index.css'

export default function TosContentSv() {
    return (
        <CSSTransition
            in={true}
            appear={true}
            timeout={300}
            classNames={'fade'}
        >
            <div>

                <p><b>ANVÄNDARVILLKOR SERVICE NODE</b></p>

                <p><b>1. BAKGRUND</b></p>

                <p>1.1 Service Node AB, organisationsnr 559007-9892, (nedan ”<b>Service Node</b>”) har utvecklat en
                    webbaserad tjänst (nedan ”<b>Tjänsten</b>”). Tjänsten förutsätter även en applikation
                    (”<b>Applikationen</b>”) som kan laddas ned via Apple App Store eller Google Play. En mer utförlig
                    beskrivning av Tjänsten finns på <u>https://servicenode.se</u> (nedan ”<b>Hemsidan</b>”). </p>

                <p>1.2 Tjänsten initieras genom att en fysisk person registrerar ett personligt konto hos Service Node
                    (nedan ”<b>Kontot</b>”) och därefter lämnar kunduppgifter avseende en juridisk person som denne
                    önskar ansluta sig till Tjänsten (”<b>Kunden</b>”). Den som lämnar kunduppgifter till Service Node
                    garanterar i samband med registreringen att denne också är behörig att lämna dessa till Service Node
                    för Kundens räkning och att detta görs på uppdrag av Kunden. Tjänsten aktiveras därefter sedan
                    Kunden har godkänt Service Nodes avtalsvillkor samt de uppgifter om Kunden som lämnats vid
                    registreringen. </p>

                <p>1.3 De fysiska personer som använder Tjänsten benämns nedan ”<b>Användaren” </b>eller gemensamt
                    ”<b>Användarna</b>”. Godkännande av dessa användarvillkor (”<b>Användarvillkoren</b>”) är en
                    förutsättning för att Användaren ska kunna registrera ett Konto samt för att få tillgång till
                    Tjänsten. Genom att registrera Kontot accepterar Användaren Användarvillkoren. </p>

                <p><b>2. </b><b>ANVÄNDARENS KONTO</b></p>

                <p>2.1 För att Användaren ska kunna nyttja Tjänsten måste Användaren skapa ett personligt användarkonto
                    (nedan ”<b>Användarkontot</b>”). Vid skapandet av Användarkontot åligger det Användaren att
                    tillhandahålla nödvändiga, korrekta och fullständiga uppgifter. </p>

                <p>2.2 För Användarkontot används Användarens e-mailadress som användarnamn. Användarnamnet och
                    lösenordet används när Användaren ska logga in på Hemsidan och/eller Applikationen. </p>

                <p>2.3 Service Node kan inte kontrollera att det är Användaren som nyttjar Tjänsten utan endast att rätt
                    användarnamn och lösenord anges. Användaren åtar sig därför att tillse att Användaren (i) inte
                    avslöjar eller på annat sätt tillgängliggör sitt lösenord för någon obehörig, samt (ii) tillser att
                    obehörig inte på annat sätt kan få tillgång till lösenordet. Om lösenordet likväl används av annan
                    än Användaren, eller om de under punkten 2.4 angivna reglerna för lösenord inte följs, så kan detta
                    medföra att Service Node stänger av Användaren från att nyttja Tjänsten. Om så sker ska emellertid
                    även Kunden underrättas om sådan avstängning.</p>

                <p>2.4 Användaren åtar sig att:</p>

                <p>(a) välja ett lösenord som är svårt för andra än Användaren att avslöja. Lösenordet får därför inte
                    bestå av t.ex. hela eller delar av Användarens, eller till denne närståendes, namn och/eller
                    personnummer,</p>

                <p>(b) väl förvarar eventuella noteringar om lösenordet, </p>

                <p>(c) inte avslöjar lösenordet för någon obehörig, samt</p>

                <p>(d) omedelbart ändrar lösenordet vid misstanke om att obehörig fått kännedom om detsamma.</p>

                <p>2.5 Om det finns anledning att anta att användarnamnet och lösenordet missbrukas eller har
                    missbrukats av obehörig ska Användaren genast anmäla detta till Service Node. Nämnda anmälan ska
                    innehålla uppgifter om Användarens namn, användarnamn och lösenord samt uppgift om under vilken
                    tidsperiod Kunden misstänker att användarnamnet och lösenordet har missbrukats av obehörig.
                    Användaren ansvarar för all skada som Service Node kan åsamkas till följd av att användarnamn eller
                    lösenord har missbrukats av obehörig. Detta ansvar ska gälla till dess Användaren, genom anmälan
                    till Service Node, spärrat Användarens användarnamn och lösenord.</p>
                <p><b> </b></p>
                <p><b>3. NYTTJANDE AV TJÄNSTEN</b></p>

                <p>3.1 Parterna är överens om att all upphovsrätt liksom eventuell patenträtt eller annan immateriell
                    rätt hänförlig till Tjänsten, Hemsidan och/eller Applikationen tillkommer Service Node och
                    Användaren därför inte äger rätt att nyttja Tjänsten, Hemsidan eller Applikationen utöver vad som
                    uttryckligen anges i dessa Användarvillkor. </p>

                <p>3.2 Användaren har inte rätt att till annan överlåta, överföra eller underlicensiera sina rättigheter
                    enligt dessa Användarvillkor .</p>

                <p>3.3 Såsom angetts under punkten 1.1 är Tjänsten webbaserad. Det innebär att Tjänsten nås genom
                    uppkoppling mot Internet. För sådan uppkoppling samt kommunikation erfordras t.ex. viss data-
                    och/eller annan kommunikationsutrustning, programvara samt Internetabonnemang (nedan
                    ”<b>Utrustning</b>”). Service Node har inget ansvar för sådan Utrustning. De tekniska krav som från
                    tid till annan uppställs på Användaren Utrustning finns tillgängliga på Hemsidan. Service Node
                    svarar inte för skada som drabbar Användaren till följd av fel eller brist i dennes Utrustning.
                    Användaren är införstådd med att uppgraderingar och/eller ändringar av Tjänsten kan komma att
                    medföra ändringar avseende de tekniska krav som uppställs på Användarens utrustning. Detta för att
                    Användaren ska kunna utnyttja de förbättringar som en uppgradering och/eller ändring innebär.</p>

                <p>3.4 Användaren åtar sig att,</p>

                <p>(a) vid nyttjande av Tjänsten iaktta (i) bestämmelserna i dessa Allmänna villkor, (ii) gällande
                    lagar, förordningar och myndighetsbeslut, samt (iii) Service Nodes vid var tid lämnade anvisningar
                    för nyttjandet av Tjänsten (se Hemsidan);</p>

                <p>(b) vid nyttjande av Tjänsten inte överför något material till Hemsidan och/eller Applikationen som
                    kan innehålla virus, trojanska hästar eller någon annan kod (s.k. ”malware”) som kan skada, sätta ur
                    funktion, överbelasta eller försämra Hemsidan och/eller Tjänsten eller inkräkta på Annan Användares
                    nyttjande av Hemsidan och/eller Applikationen. Med ”<b>Annan Användare</b>” avses annan Användare
                    som nyttjar Tjänsten på motsvarande sätt som Kunden;</p>

                <p>(c) inte vidtar åtgärder som har till syfte att kringgå Service Nodes säkerhetssystem – t.ex. genom
                    att nyttja Annan Användares användarnamn och lösenord eller att lämna ut användarnamn och lösenord
                    till annan – eller försöka testa säkerheten på Hemsidan och/eller Applikationen utan att i förväg ha
                    erhållit Service Nodes skriftliga godkännande;</p>

                <p>(d) inte dekompilera/r (reverse engineer) Hemsidan, Applikationen och/eller Tjänsten;</p>

                <p>(e) inte vidtar åtgärder som har till syfte att bereda Kund/Användare otillåten tillgång till något
                    datasystem eller nätverk som omfattas av Hemsidan och/eller Applikationen; samt</p>

                <p>(f) inte skaffa/r fram eller försöka skaffa fram material eller information som inte avsiktligen
                    gjorts tillgängligt eller tillhandahållits via Hemsidan och/eller Applikationen</p>

                <p>3.5 Brott mot eller försök till brott mot en, flera eller samtliga av bestämmelserna under punkten
                    4.4 kan vara brottsligt och Service Node kan därför vid behov komma att involvera berörda
                    myndigheter för att hantera denna typ av brott.</p>

                <p><b>4. DRIFT</b></p>

                <p>4.1 Tjänsten tillhandahålls i regel 24 timmar per dygn, sju dagar i veckan och 365 dagar per år.
                    Service Node garanterar emellertid inte att Tjänsten och Hemsidan är fria från fel eller avbrott
                    under ovanstående tid. </p>

                <p>4.2 Då Tjänsten är webbaserat är Användaren införstådd med att tillfälliga avbrott och fördröjningar
                    på Internet inte utgör ett fel eller avbrott i Tjänsten. Service Node har ingen skyldighet att
                    åtgärda fel eller avbrott som ligger utanför Service Nodes kontroll som Service Node inte skäligen
                    kunde förväntas ha räknat med och vars följder Service Node inte skäligen kunde ha undvikit eller
                    övervunnit som t.ex, men inte begränsat till, fel eller avbrott vad gäller Användarens
                    Internetuppkoppling, Utrustningen eller förekomsten av sådan omständighet som anges under punkten
                    12.3. </p>

                <p>4.3 Tjänsten kan från tid till annan tas ur bruk för att åtgärder (nedan ”<b>Åtgärd</b>”) som t.ex,
                    men inte begränsat till, underhåll, service och uppdatering ska kunna vidtas. Service Node
                    förbehåller sig rätten att göra avbrott i tillhandahållande av Tjänsten för att vidta sådan Åtgärd.
                    Sådana avbrott ska i möjligaste mån inplaneras på tider när nyttjandet av Tjänsten normalt sett är
                    lågt. </p>

                <p>4.4 Service Node äger rätt att när som helst och utan föregående meddelande stänga av Hemsidan
                    och/eller tillgången till Tjänsten för att skydda Hemsidan och/eller Tjänsten från obehöriga angrepp
                    eller motsvarande.</p>

                <p>4.5 Service Node äger rätt att med omedelbar verkan och tillsvidare stänga av Användarna från
                    nyttjande av Tjänsten (i) om Användare vid något tillfälle inte efterlever dessa Allmänna villkor
                    eller om Service Node på skälig grund antar att så är fallet. </p>

                <p><b>5. SUPPORT </b></p>

                <p>5.1 Service Node tillhandahåller Support vad avser Tjänsten i följande omfattning. Med
                    ”<b>Support</b>” avses stöd för att Användaren ska kunna nyttja Tjänsten på avsett sätt. Support
                    tillhandahålls <i>i första hand</i> via hjälpavsnittet på Hemsidan, <i>i andra hand</i> per e-mail
                    via formulär på Hemsidan eller via e-mail till <u>support@</u><u>servicenode.se</u> Service Node
                    tillhandahåller manuell support endast under Kontorstid måndag till fredag med undantag för svenska
                    helgdagar då någon felavhjälpning inte sker och dag före Svenska Helgdagar då felavhjälpning endast
                    sker mellan kl 08.00 och 12.00 (svensk tid). </p>

                <p>5.2 Användaren är skyldig att vid Support via formulär på Hemsidan eller per e-mail tydligt ange vad
                    problemet består i. Härutöver är Kunden, om den personal som arbetar med supportärendet så påkallar,
                    skyldig att förevisa problemet.</p>

                <p>5.3 För tydlighets skull noteras det att Service Node inte tillhandahåller support avseende
                    utrustning som Kunden ansvarar för t.ex, men inte begränsat till, Utrustningen.</p>

                <p><b>6. IMMATERIELLA RÄTTIGHETER</b></p>

                <p>6.1 Samtliga immateriella rättigheter och tekniska lösningar avseende Hemsidan, Applikationen
                    och/eller Tjänsten är Service Nodes egendom alternativt egendom tillhörande tredje man med vilken
                    Service Node samarbetar. Användaren åtar sig att inte nyttja immateriella rättigheter eller tekniska
                    lösningar avseende Hemsidan, Applikationen och/eller Tjänsten utöver vad som krävs för Tjänstens
                    nyttjande.</p>

                <p>6.2 Användaren åtar sig att inte ändra, publicera, vidarebefordra, distribuera, förevisa eller delta
                    i överlåtelse eller försäljning, skapa sekundära verk av eller på annat sätt kommersialisera
                    (exempelvis i tryckt form eller på annan webbsida eller nätansluten dator eller mobil enhet), vare
                    sig helt eller delvis, något av innehållet på/i Hemsidan och/eller Tjänsten.</p>

                <p>6.3 I förhållandet mellan Service Node och Kunden är Användaren införstådd med att samtliga
                    rättigheter till den information som Kunden överför via Tjänsten (nedan ”<b>Informationen</b>”)
                    tillhör Kunden. Detta gäller även den information som genereras genom Kundens användning av Tjänsten
                    (nedan ”<b>Bearbetad Information</b>”).</p>

                <p>6.4 Service Node äger endast rätt att förfoga över Informationen i den utsträckning som är nödvändig
                    för att Service Node ska kunna fullgöra sina åtaganden enligt avtalet med Kunden. Härutöver äger
                    Service Node rätt att – under avtalstiden – använda Information i Anonymiserad Form i syfte att
                    utveckla och förbättra Tjänsten. Med ”<b>Anonymiserad Form</b>” avses i dessa Allmänna villkor
                    (i) <u>att</u> det inte framgår att Informationen kommer från Användaren, samt
                    (ii) <u>att</u> Informationen inte innehåller sådan information som omfattas av punkten 7.
                    Användaren är inte berättigad till någon ersättning för sådant nyttjande av Kundens information.</p>

                <p>6.5 Användaren garanterar </p>

                <p>(a) <u>att</u> Informationen inte (i) innehåller upphovsrättsligt skyddat material tillhörande tredje
                    man eller material som är föremål för tredje mans äganderätt eller som tredje man på annat sätt
                    innehar rättigheterna till, eller (ii) på annat sätt står i strid mot gällande lagstiftning, samt
                </p>

                <p>(b) <u>att</u> Användaren – för det fall Informationen innehåller upphovsrättsligt skyddat material
                    tillhörande tredje man; material som är föremål för tredje mans äganderätt; eller som tredje man på
                    annat sätt innehar rättigheterna till – (i) har rätt att nyttja Informationen, eller (ii) på annat
                    sätt har en juridisk rätt att lägga upp Informationen.</p>

                <p>6.6 Vid misstanke (i) om brist i någon av de under punkterna 6.5 (a) eller (b) lämnade garantierna,
                    eller (ii) att Informationen i något annat avseende strider mot dessa Allmänna villkor, äger Service
                    Node rätt att ensidigt avgöra huruvida Information uppfyller de krav som uppställs i dessa Allmänna
                    villkor. Service Node äger därför rätt att utan föregående meddelande till Användaren ta bort
                    Information som laddats upp genom Tjänsten. Om så sker ska emellertid Användaren underrättas om
                    sådan borttagning. </p>

                <p><b>7. </b><b>PERSONUPPGIFTER</b></p>

                <p>7.1 I förhållande till Kunden</p>

                <p>7.1.1 Kunden är personuppgiftsansvarig för all eventuell personuppgiftsbehandling som må äga rum i
                    samband med Kundens nyttjande av Tjänsten. Kunden är ansvarig för att all sådan
                    personuppgiftsbehandling äger rum i enlighet med personuppgiftslagen (1998:204) samt övrig vid var
                    tidpunkt gällande lagstiftning på området.</p>

                <p>7.2 I förhållande till Användaren</p>

                <p>7.2.1 För att Användaren ska kunna skapa Kontot krävs att denna anger (i) för- och efternamn, (ii)
                    e-postadress samt (iii) lösenord. Därutöver kan användaren frivilligt ange (iv) adress samt (v)
                    telefonnummer (i)-(iii) benämns i det följande som <b>Användaruppgifterna</b> och (iv)-(v) <b>Frivilliga
                        Uppgifterna</b>. Användaruppgifterna samt de Frivilliga Uppgifterna benämns nedan gemensamt
                    som <b>Personuppgifterna</b>. Det är frivilligt att lämna de Frivilliga Uppgifterna men Användaren
                    är införstådd med att Kontot inte kan skapas om Användaruppgifterna inte lämnas. </p>

                <p>7.2.2 Service Node är ansvarigt för Personuppgifterna. Personuppgifterna samlas in och behandlas
                    enbart i syfte att kunna registrera Kontot och administrera Tjänsten. För utförandet av Tjänsten kan
                    Personuppgifterna komma att lämnas ut till Kunden samt till av Service Node anlitade
                    underleverantörer och konsulter. Service Node kommer inte att lämna ut Personuppgifterna till
                    utomstående annat än i de fall då (i) det särskilt överenskommits mellan Service Node och
                    Användaren, (ii) då det inom ramen för ett visst uppdrag är nödvändigt för att tillvarata
                    Användarens rättigheter, (iii) om det är nödvändigt för att Service Node ska fullgöra lagstadgad
                    skyldighet eller efterkomma myndighetsbeslut eller beslut av domstol, eller (iv) för det fall att
                    Service Node anlitar en utomstående tjänsteleverantörer som utför uppdrag för bolagets räkning.
                    Dessa tjänsteleverantörer är då skyldiga att enbart använda Personuppgifterna för Service Nodes
                    räkning och åtar sig att skydda dessa. Personuppgifterna kommer inte att lämnas ut till utomstående
                    i marknadsföringssyfte. </p>

                <p>7.2.3 Användaren samtycker även till att Personuppgifterna kan komma att föras utanför EU/EES.
                    Personuppgifterna kan när som helst återkalla sitt samtycke till Service Nodes hantering av
                    Personuppgifterna genom att skriftligen meddela detta till Service Node. Om samtycket återkallas
                    äger Service Node rätt att omedelbart stänga av Kontot. </p>

                <p>7.2.4 Användaren har rätt att gratis efter skriftligt undertecknad ansökan ställd till Service Node,
                    få besked om vilka Personuppgifter som bolaget behandlar och hur dessa behandlas. Användaren har
                    också rätt att begära rättelse i fråga om Personuppgifter som Service Node behandlar om denne.
                    Användaren har även rätt att begära rättelse av Personuppgifter. En eventuell begäran om sådan
                    rättelse ska framställas skriftligen till Service Node. Användarens Personuppgifter kommer inte att
                    behandlas för längre tid än vad som är nödvändigt. </p>

                <p>7.2.5 Genom att (i) registrera Kontot samt (ii) godkänna dessa Användarvillkor, samtycker Användaren
                    till den behandling av Personuppgifterna som anges ovan.</p>

                <p>7.2.6 Förfrågningar och information om vår behandling av personuppgifter lämnas av på begäran genom
                    kontakt på <u>support@servicenode.se</u>. För närmare information om gällande lagstiftning, vilket
                    ansvar Service Node har för behandlingen och vilka rättigheter du har, se <u><a
                        href="http://www.datainspektionen.se/dataskyddsreformen/"
                        rel="nofollow">http://www.datainspektionen.se/dataskyddsreformen/</a></u>. </p>

                <p><b>8. ÄNDRINGAR</b></p>

                <p>8.1 Service Node har alltid rätt att göra Ändring i Användarvillkoren som är av ringa betydelse för
                    Användaren. Sådana ändringar träder i kraft omedelbart och Service Node är inte skyldigt att
                    underrätta Användaren om sådana ändringar för att de ska vara gällande för Användaren. Service Node
                    äger rätt att göra ändringar i eller tillägg till Användarvillkoren (nedan gemensamt
                    ”<b>Ändringar</b>”) som är påkallade av (i) ändring av lag eller annan författning, (ii)
                    domstolsavgörande, (iii) myndighetsbeslut, eller (iv) att Service Node blir uppköpt av, köper upp
                    eller går samman med annat bolag. Sådana Ändringar träder i kraft tre (3) månader efter det att
                    Meddelande om förändringen har kommit Användaren tillhanda. Vid Ändringar äger Användaren rätt att,
                    senast en (1) månad före det att Ändringen träder i kraft, avsluta Kontot. Om Användaren inte
                    avslutat Kontot senast en (1) månad före det att ändringen träder i kraft ska Användaren anses ha
                    accepterat Ändringen. </p>

                <p>8.2 Andra ändringar i eller tillägg till dessa Användarvillkor (än sådana som anges under punkten
                    8.1.1) ska för att vara gällande godkännas Användaren. </p>

                <p><b>9. TVIST </b></p>

                <p>9.1 På dessa Allmänna villkor ska svensk lag tillämpas.</p>

                <p>9.2 Tvister i anledning av dessa Allmänna villkors tillkomst, tillämpning eller tolkning, så ock
                    annan tvist som har sin upprinnelse i dessa ska avgöras av allmän domstol i Sverige. </p>
            </div>
        </CSSTransition>
    )
}