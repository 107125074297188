import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  data: {},
  isLoading: true,
  isError: false,
  isNotFound: true,
};
const comsettingsSlice = createSlice({
  name: "comSettingTodos",
  initialState,
  reducers: {
    comSettingFetchingInit(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    comSettingFetchingSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        isNotFound: false,
        data: action.payload,
      };
    },
    comSettingFetchingNotFound(state) {
      return {
        ...state,
        data: {},
        isNotFound: true,
        isLoading: false,
      };
    },
    comSettingFetchingFail(state) {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    },
  },
});

const { actions, reducer } = comsettingsSlice;

export const {
  comSettingFetchingFail,
  comSettingFetchingInit,
  comSettingFetchingNotFound,
  comSettingFetchingSuccess,
} = actions;

export default reducer;
