import axios from "axios";
import { logout } from "../../actions/loginActions";
import api, { url } from "../../app/appAPI";
import { BASE_API_URL } from "../../constant/app";
import { showSuccessAlert } from "../../lib";
import store from "../../redux";
import {
  refreshTokenStart,
  refreshTokenSuccess,
} from "../../redux/reducers/authReducer";
import { updateCompanyUserMap } from "../../redux/reducers/companiesReducer";
import { fetchUserDataSuccess } from "../../redux/reducers/userReducer";

export const getUserProfile = async (
  postGetProfile?: (profile: any) => void
) => {
  return await api.get(url.MY_PROFILE).then((response) => {
    store.dispatch(fetchUserDataSuccess(response));
    if (postGetProfile) postGetProfile(response);
    return response;
  });
};
export function fetchCompanies() {
  api.get(url.MY_PROFILE_COMPANY).then((response) => {
    store.dispatch(updateCompanyUserMap(response?.results));
  });
}
const TOKEN_CONFIG = {
  headers: { "Content-Type": "text/plain" },
};
function getRefreshTokenParams() {
  const params = new FormData();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", store.getState().auth.refresh_token);

  return params;
}

export const refreshUserToken = async () => {
  const queryString = window.location.pathname;
  var isLogin = queryString.includes("/login");

  store.dispatch(refreshTokenStart());
  return axios
    .post(url.REFRESH_TOKEN, getRefreshTokenParams(), TOKEN_CONFIG)
    .then((response) => {
      store.dispatch(refreshTokenSuccess(response.data));
    })
    .catch((error) => {
      if (!isLogin) logout();
    });
};
export const testServer = () => {
  axios.get(`${BASE_API_URL}/health/`).then((response) => {});
};
export function acceptTos(setLoading) {
  setLoading(true);
  api
    .patch(url.MY_PROFILE, {
      tos_accepted: true,
    })
    .then((response) => {
      if (response && response.status === 200) {
        showSuccessAlert("terms_of_service.accept_success_text", false, false);
      }
      setLoading(false);
    });
}
