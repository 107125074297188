import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties, FC, ReactNode } from "react";
import Translate from "../../Translate";
import SNButton, { SNButtonProps } from "../SNButton";
import SNIcon from "../SNIcon";
import "./index.scss";
interface SNEmptyProps {
  title: string | ReactNode;
  icon?: IconProp | IconName;
  style?: CSSProperties;
  extraButton?: SNButtonProps;
  italic?: boolean;
  fontSize?: number;
  subtitle?: string;
  extra?: ReactNode;
  isWarning?: boolean;
}
const SNEmpty: FC<SNEmptyProps> = (props) => {
  const {
    icon,
    style,
    title,
    extraButton,
    italic,
    fontSize,
    subtitle,
    extra,
    isWarning,
  } = props;

  if (isWarning) {
    return (
      <div>
        <SNIcon icon={["fas", "exclamation-circle"]} color="#ff9800" />{" "}
        <Translate
          i18nKey={title}
          type="secondary"
          style={{ color: "#ff9800" }}
        />
      </div>
    );
  }
  if (italic)
    return (
      <Translate
        i18nKey={title}
        style={{ fontStyle: "italic", ...style }}
        type="secondary"
      />
    );

  if (icon)
    return (
      <div className="no-data-found" style={style}>
        <SNIcon icon={icon} className="no-data-found-icon" size="2x" />
        <p className="no-data-found-message">
          <Translate i18nKey={title} />
        </p>
        {subtitle && <Translate i18nKey={subtitle} style={{ fontSize: 12 }} />}
        {extraButton && <SNButton {...extraButton} />}
        {extra}
      </div>
    );

  return (
    <Translate
      i18nKey={title}
      style={{ fontSize: fontSize || 14, ...style }}
      type="secondary"
    />
  );
};

export default SNEmpty;
