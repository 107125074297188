import React, { Component } from "react";
import Translate from "./../Translate";

export default class PageLoader extends Component {
  wrapperStyle = {
    position: "fixed",
    zIndex: 9999,
    background: "#fff",
    textAlign: "center",
    left: 0,
    right: 0,
    top: 0,
    fontSize: 18,
    fontWeight: 400,
    height: "auto",
    borderRadius: 0,
    padding: 10,
  };
  refresh = () => {
    window.location.reload();
  };
  render() {
    const { error, timedOut, pastDelay } = this.props;

    if (error || timedOut) {
      return (
        <div style={this.wrapperStyle}>
          <span className="text-danger">
            <Translate i18nKey="common_error_network_error" />
          </span>
          <button
            style={{ marginLeft: "10px" }}
            className="btn grey-btn"
            onClick={this.refresh}
          >
            <Translate i18nKey="common_error_reload_page_btn_label" />
          </button>
        </div>
      );
    } else if (pastDelay) {
      return (
        <div style={this.wrapperStyle}>
          {/* <Icon className="cog fa-spin" style={{ marginRight: 10 }} /> */}
          <Translate i18nKey="common.loader_text" />
          ...
        </div>
      );
    } else {
      return null;
    }
  }
}
