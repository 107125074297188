import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import transEN from "./config/locales/en.json";
import transSV from "./config/locales/sv.json";
import { IS_PRODUCTION } from "./constant/app";

const resources = {
  en: {
    translation: transEN,
  },
  sv: {
    translation: transSV,
  },
};

i18n
  // .use(
  //   resourcesToBackend((language, namespace, callback) => {
  //     import(`./config/locales/${language}/${namespace}.json`)
  //       .then((resources) => {
  //         callback(null, resources);
  //       })
  //       .catch((error) => {
  //         callback(error, null);
  //       });
  //   })
  // )
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: IS_PRODUCTION ? false : true,
    fallbackLng: "en",
    lng: "sv",
    ns: ["translation"],
    defaultNS: "translation",
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    parseMissingKeyHandler: (key) => (IS_PRODUCTION ? key : `Trans>>${key}<<`),
  });

export default i18n;
