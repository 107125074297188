import { Checkbox, Form, Input, Space } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import loginImage from "../../../assets/img/SVG/login.svg";
import FriendlyCaptcha from "../../../components/FriendlyCaptcha";
import SNButton from "../../../components/SN/SNButton";
import SNFormItem from "../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../components/SN/SNForm/SNInput";
import SNIcon from "../../../components/SN/SNIcon";
import Translate from "../../../components/Translate";
import { registration_url } from "../../../constant/routeURL";
import useAuth from "../../../hooks/useAuth";
import { getUserProfile } from "../../Root/service";
import AuthLayout from "../components/AuthLayout";
interface LoginPageProps {}
const LoginPage: FC<LoginPageProps> = (props) => {
  const { login } = useAuth();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onLogin = async (values) => {
    if (!isDone) return null;
    const { username, password } = values;
    setIsSubmiting(true);

    const loginRes = await login(username, password);
    if (loginRes) {
      await getUserProfile();
    }

    setIsSubmiting(false);
  };
  return (
    <AuthLayout
      title="login.form.title"
      img={loginImage}
      footer={[
        <SNButton
          link={registration_url}
          title="login.form.link_register_text"
          type="link"
        />,
      ]}
    >
      <Form
        onFinish={onLogin}
        form={form}
        size="large"
        initialValues={{ username: "", password: "" }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <SNFormItem name="username" rules={[{ type: "email" }]} required>
            <SNInput
              placeholder="login.form.email_placeholder"
              prefix={<SNIcon icon="user" />}
            />
          </SNFormItem>

          <SNFormItem name="password" required>
            <Input.Password
              placeholder={`${t("login.form.password_placeholder")}`}
              prefix={<SNIcon icon="lock-alt" />}
              onKeyUp={(e) => {
                if (e.key === "Enter") form.submit();
              }}
            />
          </SNFormItem>
          <SNFormItem name="remember" valuePropName="checked">
            <Checkbox>
              <Translate i18nKey="login.form.remember_me_label" />
            </Checkbox>
          </SNFormItem>
          <FriendlyCaptcha
            onDone={() => {
              setIsDone(true);
            }}
          />
          <SNButton
            type="primary"
            title="login.form.btn_login_label"
            style={{ height: 45 }}
            block
            onClick={form.submit}
            loading={isSubmiting}
            disabled={!isDone}
          />
          <SNButton
            type="link"
            title="login.form.link_forgetpass_text"
            link="/forget-password"
            block
          />
        </Space>
      </Form>
    </AuthLayout>
  );
};

export default LoginPage;
