import { Space } from "antd";
import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "../../actions/loginActions";
import SNButton from "../../components/SN/SNButton";
import Translate from "../../components/Translate";
import acceptTos from "./action";
import "./index.css";
import ContentEn from "./TosContentEn";
import ContentSv from "./TosContentSv";

class TermsOfService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.user_language,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user_language !== this.props.user_language) {
      this.setState({ language: this.props.user_language });
    }
  }

  changeLanguage(language) {
    this.setState({ language });
  }

  logOutHandler = (e) => {
    e.preventDefault();
    logout();
  };
  setLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  render() {
    const {
      props: { user_accepts_tos },
      state: { language },
      footer,
    } = this;

    return (
      <Modal
        show={!user_accepts_tos}
        backdrop={"static"}
        bsStyle="position-to-top"
      >
        <ModalHeader>
          <ModalTitle>
            <Translate i18nKey={"terms_of_service.modal_title"} />
          </ModalTitle>
        </ModalHeader>

        <ModalBody>
          <div className="panel-body panel-inner clearfix">
            <div className="tos-content">
              {language === "sv" ? <ContentSv /> : <ContentEn />}
            </div>
          </div>
        </ModalBody>
        {footer()}
      </Modal>
    );
  }

  footer = () => {
    const {
      state: { language },
      changeLanguage,
      logOutHandler,
    } = this;

    return (
      <ModalFooter>
        <section className={"tos-footer"}>
          <ul className="list-inline tos-lang">
            <li
              className={language === "sv" ? "active" : ""}
              onClick={changeLanguage.bind(this, "sv")}
            >
              Svenska
            </li>
            <li
              className={language === "en" ? "active" : ""}
              onClick={changeLanguage.bind(this, "en")}
            >
              English
            </li>
          </ul>

          <div>
            <Space>
              <SNButton
                title="terms_of_service.decline_btn_label"
                className="sb-btn-big"
                onClick={logOutHandler}
                key="cancel"
              />
              <SNButton
                title="terms_of_service.accept_btn_label"
                type="primary"
                className="sb-btn-big"
                onClick={() => acceptTos(this.setLoading)}
                loading={this.state.isLoading}
                key="accept"
              />
            </Space>
          </div>
        </section>
      </ModalFooter>
    );
  };
}

function mapStateToProps(state) {
  return {
    user_accepts_tos: state.user.tos_accepted,
    user_language: state.user.language,
  };
}

export default connect(mapStateToProps)(TermsOfService);
