import { Alert } from "antd";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";

import { FC, ReactNode, useEffect, useState } from "react";
import { RootState } from "../../redux/reducers";

interface WarningAffixProps {
  children: ReactNode;
}
const WarningAffix: FC<WarningAffixProps> = ({ children }) => {
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>("");

  const { user } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (user?.user_token) {
      const { user_token } = user;

      let pusher = new Pusher(process.env.REACT_APP_NOTIFICATION_TOKEN, {
        cluster: process.env.REACT_APP_CLUSTER,
      });

      const channel = pusher.subscribe(user_token);

      channel.bind("maintenance_mode", (data) => {
        console.log(data);
        setIsWarning(true);
        setWarningText(JSON.stringify(data));
      });
    }
  }, [user]);

  return (
    <>
      {isWarning && (
        <Alert
          message={warningText}
          type="warning"
          showIcon
          closable
          style={{ zIndex: 101, position: "fixed", width: "100vw" }}
          onClose={() => {
            setIsWarning(false);
          }}
        />
      )}

      <div
        className={isWarning ? "warning-container" : ""}
        style={{
          height: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default WarningAffix;
