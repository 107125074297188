import { Card } from "antd";
import { FC, ReactNode } from "react";
import logo from "../../../../assets/img/logo.svg";
import SNImage from "../../../../components/SN/SNImage";
import Translate from "../../../../components/Translate";
import SelectLang from "../LanguageSelect";
import "./index.scss";
interface AuthLayoutProps {
  title?: any;
  img?: string;
  footer?: ReactNode[];
  children?: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = (props) => {
  const { title, img = logo, footer } = props;

  return (
    <div className="sn-auth-layout-cointainer ">
      <div className="sn-auth-layout-content">
        <div className="sn-auth-layout-content-header"></div>
        <div className="sn-auth-layout-content-main">
          <Card
            className="sn-auth-layout-content-main-card"
            cover={
              <SNImage
                src={img}
                alt="Service Node Logo"
                size={150}
                noBoder
                preview={false}
              />
            }
            headStyle={{ textAlign: "center" }}
            title={<Translate i18nKey={title} />}
            actions={footer}
          >
            {props.children}
          </Card>
          <SelectLang />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
