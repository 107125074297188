import { useEffect, useState } from "react";
import appAPI, { url } from "../../../app/appAPI";
import comAPI from "../../../app/comAPI";
import { showSuccessAlert } from "../../../lib";

function useCompanyDetails(fields: string = "") {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [willReload, setWillReload] = useState<boolean>(true);

  const reload = () => {
    setWillReload(!willReload);
  };

  useEffect(() => {
    setIsLoading(true);
    comAPI
      .get(`${url.COMPANY}details/${fields ? `?fields=${fields}` : ""}`)
      .then((response) => {
        setData(response);
        setIsLoading(false);
      });
  }, [willReload, fields]);

  return [data, isLoading, reload];
}
function getDetails() {
  return comAPI.get(`${url.COMPANY}details/`).then((response) => {
    return response;
  });
}

function addCompany(params: any = {}, postActions?: (data: any) => void) {
  appAPI.post(url.COMPANY, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}
function updateCompany(params: any = {}, postActions?: (data?: any) => void) {
  comAPI.patch(`${url.COMPANY}update/`, params).then((response) => {
    if (response.status === 200) {
      showSuccessAlert("settings.company.form.add_success_text");
      if (postActions) postActions(response.data);
    }
  });
}

const companyService = {
  useCompanyDetails,
  addCompany,
  updateCompany,
  getDetails,
};
export default companyService;
