import React from 'react';
import {CSSTransition} from 'react-transition-group';
import './index.css'

export default function TosContentEn() {
    return (
        <CSSTransition
            in={true}
            appear={true}
            timeout={300}
            classNames={'fade'}
        >
            <div>

                <p><b>TERMS OF SERVICE SERVICE NODE</b></p>

                <p><b>1. BACKGROUND</b></p>

                <p>1.1 Service Node AB, organization no. 559007-9892, (hereinafter <b>"Service Node"</b>) has developed
                    a web-based service (hereinafter <b>"The Service"</b>). The service also requires an application
                    (the <b>"Application"</b>) that can be downloaded via the Apple App Store or Google Play. A more
                    detailed description of the Service can be found at <u>https://servicenode.se</u> (below
                    the <b>"Website"</b>).</p>

                <p>1.2 The service is initiated by a natural person registering a personal account with Service Node
                    (hereinafter <b>“The Account"</b>) and subsequently submitting customer information regarding a
                    legal person whom he wishes to join the Service (<b>"The Customer"</b>). Anyone who submits customer
                    information to Service Node guarantees in connection with the registration that it is also
                    authorized to submit these to Service Node on behalf of the Customer and that this is done on behalf
                    of the Customer. The service is then activated after the Customer has approved the Service Node
                    contract terms and the information about the Customer provided at the registration.</p>

                <p>1.3 The natural persons who use the Service are hereinafter referred to as <b>"The User"</b> or
                    jointly the <b>"Users"</b>. Approval of these Terms of Use (the <b>"Terms of Use"</b>) is a
                    prerequisite for the User to be able to register for an Account and to gain access to the Service.
                    By registering an Account, the User accepts the Terms of Use.</p>

                <p><b>2. USER'S ACCOUNT</b></p>

                <p>2.1 In order for the User to be able to use the Service, the User must create a personal user account
                    (hereinafter <b>"The User Account"</b>). When creating a User account, the User is obliged to
                    provide the necessary, accurate and complete information.</p>

                <p>2.2 For the User account, the User's email address is used as the user name. The user name and
                    password are used when the User is required to log in to the Website and / or the Application.</p>

                <p>2.3 Service Node cannot verify that the User is using the Service, but only that the correct user
                    name and password are entered. The user therefore undertakes to ensure that the User (i) does not
                    disclose or otherwise make its password available to any unauthorized person, and (ii) ensure that
                    unauthorized persons cannot otherwise access the password. If the password is nevertheless used by
                    someone other than the User, or if the rules for password specified under point 2.4 are not
                    followed, this may mean that Service Node will shut down the User from using the Service. If this
                    happens, however, the Customer shall also be informed of such suspension.</p>

                <p>2.4 The user undertakes to</p>

                <p>(a) Choose a password that is difficult for others than the user to disclose. The password must
                    therefore not consist of eg. all or part of the User, or to this related party's, name and / or
                    personal identification number,</p>

                <p>(b) keep any password entries,</p>

                <p>(c) does not disclose the password to any unauthorized person, as well</p>

                <p>(d) immediately change the password in case of suspicion of unauthorized access to the password.</p>

                <p>2.5 If there is reason to assume that the user name and password are being misused or misused by
                    unauthorized persons, the User shall immediately report this to Service Node. The said notification
                    shall contain information about the User's name, username and password, and information about during
                    which time period the Customer suspects that the username and password have been misused by
                    unauthorized persons. The user is responsible for any damage that Service Node may incur as a result
                    of a username or password being misused by unauthorized persons. This responsibility shall apply
                    until the User, by notification to Service Node, blocked the User's user name and password.</p>

                <p><b>3. USE OF THE SERVICE</b></p>

                <p>3.1 The parties agree that all copyrights, as well as any patent law or other intellectual property
                    rights attributable to the Service, the Website and / or the Application, shall be with the Service
                    Node and the User is therefore not entitled to use the Service, the Website or the Application
                    beyond what is expressly stated in these Terms of Use.</p>

                <p>3.2 The user does not have the right to transfer, transfer or sub-license his rights to others under
                    these Terms of Use.</p>

                <p>3.3 As stated under point 1.1, the Service is web-based. This means that the Service is accessed by
                    connecting to the Internet. For such connection and communication, for example, some data and / or
                    other communication equipment, software and Internet subscriptions (hereinafter<b> "Equipment"</b>).
                    Service Node has no responsibility for such Equipment. The technical requirements that are imposed
                    on the User Equipment from time to time are available on the Website. Service Node is not
                    responsible for any damage that may occur to the User as a result of faults or deficiencies in his
                    equipment. The user understands that upgrades and / or changes to the Service may entail changes
                    regarding the technical requirements set out on the User's equipment. This is to enable the User to
                    utilize the improvements that an upgrade and / or change entails.</p>

                <p>3.4 The user undertakes to,</p>

                <p>(a) when using the Service observe (i) the provisions of these General Terms, (ii) applicable laws,
                    ordinances and government decisions, and (iii) Service Nodes at each time provided instructions for
                    the use of the Service (see Website);</p>

                <p>(b) when using the Service does not transmit any material to the Website and / or the Application
                    that may contain viruses, Trojan horses or any other code (so-called "malware") which may damage,
                    disable, overload or impair the Website and / or the Service or infringe on Other Users' use of the
                    Website and / or the Application. <b>"Other User"</b> refers to other Users who use the Service in a
                    manner similar to the Customer;</p>

                <p>(c) does not take action to circumvent the Service Nodes security system - eg. by using Other User's
                    username and password or by issuing usernames and passwords to others - or attempting to test the
                    security on the Website and / or the Application without having received Service Nodes' written
                    approval in advance;</p>

                <p>(d) do not decompile / reverse engineer the Website, Application and / or Service;</p>

                <p>(e) does not take any action aimed at providing the Customer / User with unauthorized access to any
                    computer system or network covered by the Website and / or the Application; and</p>

                <p>(f) does not obtain or attempt to obtain materials or information not intentionally made available or
                    provided through the Website and / or the Application</p>

                <p>3.5 Violation of or attempted violation of one, several or all of the provisions of section 3.4 may
                    be criminal and Service Node may, if necessary, involve the relevant authorities to handle this type
                    of crime.</p>

                <p><b>4. OPERATION</b></p>

                <p>4.1 The service is usually provided 24 hours a day, seven days a week and 365 days a year. However,
                    Service Node does not guarantee that the Service and the Website are free from errors or
                    interruptions during the above time.</p>

                <p>4.2 When the Service is web-based, the User understands that temporary interruptions and delays on
                    the Internet do not constitute an error or interruption in the Service. Service Node has no
                    obligation to rectify faults or interruptions that are outside the Service Node's control that
                    Service Node could not reasonably have expected and whose consequences Service Node could not
                    reasonably have avoided or overcome as eg, but not limited to, error or interruption in the user's
                    Internet connection, the equipment or the existence of such circumstances as specified in paragraph
                    12.3.</p>

                <p>4.3 The service may from time to time be taken out of service in order to take measures
                    (hereinafter <b>"Action"</b>) that, for example, but not limited to, maintenance, service and
                    updating can be taken. Service Node reserves the right to discontinue the provision of the Service
                    in order to take such Action. Such interruptions should, as far as possible, be planned at times
                    when the use of the Service is normally low.</p>

                <p>4.4 Service Node is entitled at any time and without prior notice to close the Website and / or
                    access to the Service in order to protect the Website and / or the Service from unauthorized attacks
                    or equivalent.</p>

                <p>4.5 Service Node has the right, with immediate effect and for the time being, to terminate the Users
                    from the use of the Service (i) if Users at any time do not comply with these General Terms or if
                    Service Node on reasonable grounds assumes that is the case.</p>

                <p><b>5. SUPPORT</b></p>

                <p>5.1 Service Node provides Support regarding the Service to the following
                    extent. <b>"Support"</b> means support for the User to be able to use the Service in the intended
                    manner. Support is provided primarily through the help section on the Website, secondly by email via
                    form on the homepage or by e-mail to <u>support@servicenode.se</u> Service Node provides manual
                    support only during Office hours Monday to Friday with the exception of Swedish holidays when
                    someone Fault remediation does not take place and day before Swedish Holidays, when fault
                    remediation occurs only between 08.00 and 12.00 (Swedish time).</p>

                <p>5.2 The User is obliged to clearly state what the problem is in the case of Support via forms on the
                    Website or by email. In addition, if the staff working with the support case so requires, the
                    Customer is obliged to present the problem.</p>

                <p>5.3 For the sake of clarity, it is noted that Service Node does not provide support for equipment
                    that the Customer is responsible for, for example, but not limited to, the Equipment.</p>

                <p><b>6. INTELLECTUAL PROPERTY RIGHTS</b></p>

                <p>6.1 All intellectual property rights and technical solutions regarding the Website, the Application
                    and / or the Service are Service Nodes' property or property of third parties with which Service
                    Node cooperates. The user undertakes not to use intellectual property rights or technical solutions
                    regarding the Website, the Application and / or the Service in addition to what is required for the
                    Service's use.</p>

                <p>6.2 The User undertakes not to alter, publish, transmit, distribute, display or participate in the
                    transfer or sale, create secondary works of or otherwise commercialize (for example, in printed form
                    or on any other website or on-line computer or mobile device), whether or not wholly or partly, any
                    of the content on / in the Website and / or the Service.</p>

                <p>6.3 In the relationship between Service Node and the Customer, the User understands that all rights
                    to the information that the Customer transfers via the Service (hereinafter <b>"The Information"</b>)
                    belong to the Customer. This also applies to the information generated by the Customer's use of the
                    Service (hereinafter <b>"Processed Information"</b>).</p>

                <p>6.4 Service Node only has the right to dispose of the Information to the extent necessary for Service
                    Node to be able to fulfill its obligations under the agreement with the Customer. In addition,
                    Service Node has the right - during the contract period - to use Information in Anonymized Form in
                    order to develop and improve the Service. <b>"Anonymized Form"</b> in these General Terms and
                    Conditions (i) means that it does not appear that the Information comes from the User, and (ii) that
                    the Information does not contain such information as is covered by paragraph 7. The user is not
                    entitled to any compensation for such use of Customer information.</p>

                <p>6.5 The user guarantees</p>

                <p>(a) that the Information does not (i) contain any copyrighted material of a third party or any
                    material which is the property of a third party, or which any third party otherwise holds, or (ii)
                    otherwise contravenes applicable law; and</p>

                <p>(b) the User - in the event that the Information contains copyrighted material of a third party;
                    materials that are the property of third parties; or as third party otherwise holds the rights to -
                    (i) have the right to use the Information, or (ii) otherwise has a legal right to post the
                    Information.</p>

                <p>6.6 In the event of any suspicion (i) of a defect in any of the warranties provided under paragraphs
                    6.5 (a) or (b), or (ii) that the information in any other respect contravenes these General Terms,
                    Service Node is entitled to unilaterally determine whether Information meet the requirements set out
                    in these General Terms and Conditions. Service Node therefore has the right to remove Information
                    uploaded by the Service without notice to the User. If this happens, however, the User shall be
                    notified of such removal.</p>

                <p><b>7. PERSONAL DATA</b></p>

                <p>7.1 In relation to the Customer</p>

                <p>7.1.1 The customer is responsible for the personal data of any personal data processing that may take
                    place in connection with the Customer's use of the Service. The customer is responsible for ensuring
                    that all such personal data processing takes place in accordance with the Personal Data Act (1998:
                    204) and other legislation in force in the area at any given time.</p>

                <p>7.2 In relation to the User</p>

                <p>7.2.1 In order for the User to be able to create an Account, it must specify (i) first and last name,
                    (ii) e-mail address and (iii) password. In addition, the user may voluntarily specify (iv) address
                    and (v) telephone number (i) - (iii) hereinafter referred to as <b>User Information</b> and (iv) -
                    (v) <b>Voluntary Information</b>. The user information and the Voluntary Information are
                    collectively referred to below as the <b>Personal Data</b>. It is voluntary to leave the Voluntary
                    Information, but the User understands that the Account cannot be created if the User Information is
                    not provided.</p>

                <p>7.2.2 Service Node is responsible for the Personal Data. The personal data is collected and processed
                    solely for the purpose of registering the Account and administering the Service. For the performance
                    of the Service, the Personal Data may be disclosed to the Customer and subcontractors and
                    consultants engaged by Service Node. Service Node will not disclose the Personal Data to outsiders
                    other than in cases where (i) it is specifically agreed between the Service Node and the User, (ii)
                    when it is necessary within the framework of a specific assignment to safeguard the User's rights,
                    (iii) whether it is necessary for Service Node to fulfill statutory obligation or to comply with
                    governmental decisions or court rulings, or (iv) in the event that Service Node engages an outside
                    service provider who is acting on behalf of the Company. These service providers are then obliged to
                    use the Personal Data only on behalf of Service Nodes and undertake to protect them. The personal
                    data will not be disclosed to third parties for marketing purposes.</p>

                <p>7.2.3 The user also agrees that the Personal Data may be brought outside the EU / EEA. The personal
                    data may at any time withdraw their consent to Service Node's handling of the Personal Data by
                    notifying the Service Node in writing. If the consent is revoked, Service Node has the right to
                    immediately cancel the Account.</p>

                <p>7.2.4 The user has the right to be informed, free of charge, in writing of the application submitted
                    to Service Node, of which Personal Information the company is handling and how these are processed.
                    The user also has the right to request a correction regarding Personal Data that Service Node deals
                    with about it. The user is also entitled to request correction of Personal Data. Any request for
                    such correction shall be made in writing to Service Node. The user's Personal Data will not be
                    processed for longer than is necessary.</p>

                <p>7.2.5 By (i) registering the Account and (ii) accepting these Terms of Use, the User agrees to the
                    processing of the Personal Data provided above.</p>

                <p>7.2.6 Inquiries and information about our processing of personal data are provided on request by
                    contacting <u>support@servicenode.se</u>. For more information on current legislation, what
                    responsibility Node has for the treatment and what rights you have,
                    see <u>https://www.datainspektionen.se/other-lang/in-english/the-general-data-protection-regulation-gdpr/</u>
                </p>

                <p><b>8. CHANGES</b></p>

                <p>8.1 Service Node always has the right to make Changes in the Terms of Use which are of little
                    importance to the User. Such changes take effect immediately and Service Node is not obliged to
                    notify the User of such changes in order for them to be valid for the User. Service Node has the
                    right to make changes or additions to the Terms of Use (hereinafter jointly <b>"Changes"</b>) that
                    are required by (i) a change of law or other constitution, (ii) court ruling, (iii) authority
                    decision, or (iv) that Service Node will be acquired by, buy up or merge with another company. Such
                    Amendments shall enter into force three (3) months after the Notice of the change has been received
                    by the User. In the case of Amendments, the User is entitled to terminate the Account no later than
                    one (1) month before the Amendment enters into force. If the User does not terminate the Account no
                    later than one (1) month before the change enters into force, the User shall be deemed to have
                    accepted the Amendment.</p>

                <p>8.2 Other changes or additions to these Terms of Use (than those specified under item 8.1.1) shall be
                    approved by the User in order to be valid.</p>

                <p><b>9. DISPUTE</b></p>

                <p>9.1 On these General Terms and Conditions Swedish law shall apply.</p>

                <p>9.2 Disputes arising out of the existence, application or interpretation of these General Terms and
                    Conditions, as well as other disputes that have their origin in these shall be settled by a public
                    court in Sweden.</p>
            </div>
        </CSSTransition>
    )
}