import React from "react";
import ActivityTextWithLink from "./ActivityTextWithLink";
import ContentText from "./ContentText";

function TextGreen(props) {
  return (
    <span className="text-sn-green" {...props}>
      {props.children}
    </span>
  );
}

function TextBlue(props) {
  return (
    <span className="text-sn-blue" {...props}>
      {props.children}
    </span>
  );
}

function TextRed(props) {
  return (
    <span className="text-sn-red" {...props}>
      {props.children}
    </span>
  );
}

function TextYellow(props) {
  return (
    <span className="text-sn-yellow" {...props}>
      {props.children}
    </span>
  );
}

function SharingInfo(props) {
  return (
    <div className="sharing-info">
      <h4>{props.title}</h4>
      <p>
        {props.description && props.description !== ""
          ? props.description
          : "-"}
      </p>
    </div>
  );
}

function TextColor(props) {
  return <span style={{ color: props.color }}>{props.children}</span>;
}

export const GreenText = TextGreen;
export const BlueText = TextBlue;
export const RedText = TextRed;
export const YellowText = TextYellow;
export const ShareText = SharingInfo;
export const ActivityText = ActivityTextWithLink;
export const ContentType = ContentText;
export const ColorText = TextColor;
