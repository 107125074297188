import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import { useTranslation } from "react-i18next";

interface SNInputProps extends InputProps {
  noTranslatePlaceholder?: boolean;
}

function SNInput(props: SNInputProps) {
  const { placeholder, noTranslatePlaceholder, showCount, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Input
      {...rest}
      placeholder={
        (placeholder &&
          (noTranslatePlaceholder ? placeholder : `${t(placeholder)}`)) ||
        ""
      }
      maxLength={showCount && 256}
      showCount={
        showCount && {
          formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
        }
      }
    />
  );
}

export default SNInput;
