import "url-search-params-polyfill";
import store from "../../redux";
import { processEnd, processStart } from "../../redux/reducers/appReducer";
import { handleServerErrors } from "../../utils/serverAlert";
import axios from "./api";
import endpoint from "./endpoints";

export interface TokenData {
  access_token: string;
  refresh_token: string;
}

function get(url, showServerMessage = true, cancelToken?, getRes = false) {
  return axios
    .get(url, { cancelToken: cancelToken })
    .then((res) => {
      if (getRes) return res;

      return res.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        window.location.pathname = "/maintenance";
      } else {
        return handleServerErrors(error, showServerMessage);
      }
    });
}

function patch(url, params?, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .patch(url, params)
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}

function patchNoLoading(
  url,
  params?,
  isFormData = false,
  showServerMessage = true
) {
  if (isFormData) params = processFormData(params);

  return axios
    .patch(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}

function post(url, params, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .post(url, params)
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());
      return handleServerErrors(error, showServerMessage);
    });
}
function postNoLoading(
  url,
  params,
  isFormData = false,
  showServerMessage = true
) {
  if (isFormData) params = processFormData(params);

  return axios
    .post(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function put(url, params, isFormData = false, showServerMessage = true) {
  if (isFormData) params = processFormData(params);

  return axios
    .put(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function putNoLoading(
  url,
  params,
  isFormData = false,
  showServerMessage = true
) {
  if (isFormData) params = processFormData(params);

  return axios
    .put(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleServerErrors(error, showServerMessage);
    });
}
function del(url, params?, isFormData = false, showServerMessage = true) {
  store.dispatch(processStart());
  if (isFormData) params = processFormData(params);

  return axios
    .delete(url, { data: params })
    .then((response) => {
      store.dispatch(processEnd());
      return response;
    })
    .catch((error) => {
      store.dispatch(processEnd());

      return handleServerErrors(error, showServerMessage);
    });
}

function processFormData(data) {
  let formData = new FormData();
  for (const key of Object.keys(data)) {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      (data[key].hasOwnProperty("fileName") ||
        data[key].hasOwnProperty("name") ||
        data[key].hasOwnProperty("file"))
    ) {
      formData.append(
        key,
        data[key].value,
        data[key].fileName || data[key].name
      );
    } else {
      formData.append(key, data[key]);
    }
  }

  if (data.hasOwnProperty("blobFileName")) {
    const key = data.blobFileName.key;

    formData.append(key, data[key], data.blobFileName.name);
  }
  return formData;
}

export const url = endpoint.url;
export default {
  get,
  patch,
  post,
  postNoLoading,
  putNoLoading,
  put,
  del,
  patchNoLoading,
};
