import { PATH_MYPAGE } from "./screens/Root/paths";

export const HOST_API = process.env.REACT_APP_API_URL || "";
export const FRIENDLY_CAPTCHA_KEY =
  process.env.REACT_APP_FRIENDLY_CAPTCHA_KEY || "";
export const PATH_AFTER_LOGIN = PATH_MYPAGE.root;

export const ACCESS_TOKEN_NAME = "accessToken";
export const REFRESH_TOKEN_NAME = "refreshToken";

export const COMPANY_ACCESS_TOKEN_NAME = "companyAccessToken:";
export const COMPANY_REFRESH_TOKEN_NAME = "companyRefreshToken:";

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;
