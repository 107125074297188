import axios from "axios";
import { HOST_API } from "../config";
import i18n from "../i18n";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": i18n.language,
  },
});

export default axiosInstance;
