/* eslint-disable import/no-anonymous-default-export */
const API_VERSION = process.env.REACT_APP_API_VERSION;
const base = `/${API_VERSION}`;

export const ASSET = `${base}/asset/`;
export const ASSET_TYPE = `${base}/asset-type/`;
export const ASSET_SUBTYPE = `${base}/asset-subtype/`;
export const ASSET_IMPORT = `${base}/asset/import/`;
export const ACTIVITIES = `${base}/activity/`;
export const ASSET_BULK_UPDATE = `${base}/asset/bulk_update/`;

export const BASE = base;

export const CHECKLIST = `${base}/checklist/`;
export const CUSTOMER = `${base}/customer/`;
export const CUSTOMER_IMPORT = `${base}/customer/import/`;
export const CONTACT = `${base}/contact/`;
export const CONTACT_ATTACH = `${CONTACT}attach/`;
export const CONTACT_IMPORT = `${base}/contact/import/`;
export const CONTRACT = `${base}/contract/`;
export const COMPANY = `${base}/company/`;
export const CHECKED_LIST = `${base}/checklist/`;
export const COST_CENTRE = `${base}/costcentre/`;

export const DOCS = `${base}/docs/`;
export const DOCUMENT = `${base}/attachment/document/`;
export const DOCUMENT_ATTACH = `${base}/attachment/document/attach/`;
export const DOCUMENT_CATEGORY = `${base}/attachment/document/category/`;

export const ERROR = `${base}/error/`;
export const ERROR_TEMPLATE = `${base}/error/error_template/`;

export const EMPLOYEE = `${base}/employee/`;
export const EVENT = `${base}/event/`;
export const EMAIL_ATTACHMENT = `${base}/attachment/email/`;
export const EXPORT = `${base}/base/export/`;
export const EXPORT_V2 = (entity) => `${base}/${entity}/export/`;
export const EXPORT_V2_FIELDS = (entity) => `${base}/${entity}/export/fields/`;

export const FORM = `${base}/attachment/form/`;
export const FORM_ENTITY = `${base}/attachment/entity/`;

export const IMAGE = `${base}/attachment/image/`;
export const IMAGE_ATTACHMENT = `${base}/attachment/image/attach/`;
export const INVOICE = `${base}/invoice/`;
export const INVOICE_PAYMENT = `${base}/invoice/payment/`;
export const INTEGRATION = `${base}/integration/`;
export const JOB = `${base}/job/`;

export const MY_USERS = `${base}/company/users/`;
export const MFC = `${base}/mfc/`;
export const MODEL = `${base}/model/`;
export const MYSELF = `${base}/company/users/me/`;
export const METER = `${base}/meter/`;
export const METER_TEMPLATE = `${base}/meter/template/`;
export const METER_READING = `${base}/meter/reading/`;

export const NOTIFICATION = `${base}/notification/`;
export const NEWSFEED = `${base}/newsfeed/`;
export const NOTE = `${base}/attachment/note/`;
export const NOTE_ATTACH = `${NOTE}attach/`;
export const NODE_LIST = `${base}/snode/`;

export const PARTNER = `${base}/partner/`;
export const PARTS = `${base}/parts/`;
export const PARTS_INVENTORY = `${base}/parts/inventory/`;
export const PARTS_TYPE = `${base}/parts/type/`;
export const PARTS_GROUP = `${base}/parts/group/`;
export const PARTS_IMPORT = `${base}/parts/import/`;
export const PLACES = `${base}/place/`;
export const PLACES_ATTRIBUTE = `${base}/place/attribute/`;
export const PLACES_ASSETS = `${base}/place/asset/`;
export const PLACES_TYPES = `${base}/place/type/`;
export const PLACES_GROUPS = `${base}/place/group/`;
export const PLACES_GROUPS_ASSIGN = `${PLACES_GROUPS}/assign/`;

export const REFRESH_TENANT_TOKEN = `${base}/tenant/refresh_token/`;

export const SERVICE_CUST = `${base}/servicecustomer/`;
export const SNODE = `${base}/snode/`;
export const SETTINGS_INVOICE = `${base}/company/settings/invoice`;
export const SETTINGS_GENERAL = `${base}/company/settings/general`;
export const SETTINGS_GENERAL_TIMEZONE = `${SETTINGS_GENERAL}/timezone`;

export const SERVICE = `${base}/service/`;
export const SERVICE_OVERVIEW = `${SERVICE}overview/`;
export const SERVICED_USER_BY_COMPANY = `${base}/company/users/`;
export const SUPPLIER = `${base}/supplier/`;
export const SUPPLIER_TYPE = `${base}/supplier/type/`;
export const STATISTICS = `${base}/stats/company/`;
export const SERVICE_PLAN_RECUR = `${base}/serviceplan/`;
export const SERVICE_PLAN_SCHEDULED = `${base}/serviceplan/occurrence/`;

export const TENANT_TOKEN = "/v4/tenant/token/";
export const TEAM = `${base}/team/`;
export const TIME_LOG = `${base}/timelog/`;
export const TASK = `${base}/task/`;
export const TASK_TYPE = `${base}/task/type/`;
export const TASK_PARTS = `${base}/task/parts/`;
export const TAG = `${base}/tag/`;

export const PUBLIC_PAGE = `${base}/error/public_page/`;
export const POLICY = `${base}/policy/`;

export const USER_PROFILE = `${base}/user/profile/`;
export const UNIT = `${base}/base/get_units/`;

export const WORK_ORDER = `${base}/workorder/`;
export const WORK_ORDER_TYPE = `${base}/workorder/type/`;
export const WORK_ORDER_PARTS = `${base}/workorder/parts/`;
export const WORK_ORDER_ASSETS = `${base}/workorder/assets/`;
export const WORK_ORDER_TASKS = `${base}/workorder/tasks/`;
export const WORK_ORDER_COSTS = `${base}/workorder/costs/`;
export const WAREHOUSE = `${base}/warehouse/`;
export const RECAPTCHA_VERIFY = `${base}/integration/siteverify/`;

export default {
  url: {
    SERVICE,
    ERROR,
    NODE_LIST,
    MY_USERS,
    SERVICED_USER_BY_COMPANY,
    CHECKED_LIST,
    ASSET,
    ASSET_BULK_UPDATE,
    ASSET_TYPE,
    ASSET_SUBTYPE,
    DOCS,
    CUSTOMER,
    CUSTOMER_IMPORT,
    TAG,
    CHECKLIST,
    PARTNER,
    EMPLOYEE,
    MFC,
    EVENT,
    NEWSFEED,
    ACTIVITIES,
    MODEL,
    SNODE,
    USER_PROFILE,
    ASSET_IMPORT,
    EXPORT,
    EXPORT_V2,
    EXPORT_V2_FIELDS,
    FORM,
    FORM_ENTITY,
    IMAGE,
    IMAGE_ATTACHMENT,
    EMAIL_ATTACHMENT,
    DOCUMENT,
    DOCUMENT_CATEGORY,
    TASK_TYPE,
    TASK,
    TASK_PARTS,
    SERVICE_OVERVIEW,
    SUPPLIER,
    PARTS,
    PARTS_INVENTORY,
    PARTS_TYPE,
    PARTS_GROUP,
    PARTS_IMPORT,
    WORK_ORDER,
    WORK_ORDER_TYPE,
    WORK_ORDER_PARTS,
    WORK_ORDER_ASSETS,
    WORK_ORDER_TASKS,
    WORK_ORDER_COSTS,
    TEAM,
    CONTACT,
    CONTACT_ATTACH,
    CONTACT_IMPORT,
    CONTRACT,
    DOCUMENT_ATTACH,
    NOTE,
    NOTE_ATTACH,
    BASE,
    STATISTICS,
    SERVICE_PLAN_SCHEDULED,
    SERVICE_PLAN_RECUR,
    TIME_LOG,
    WAREHOUSE,
    INVOICE,
    INVOICE_PAYMENT,
    PLACES,
    PLACES_ATTRIBUTE,
    PLACES_ASSETS,
    PLACES_TYPES,
    PLACES_GROUPS,
    PLACES_GROUPS_ASSIGN,
    SETTINGS_INVOICE,
    SETTINGS_GENERAL,
    SETTINGS_GENERAL_TIMEZONE,
    SERVICE_CUST,
    COMPANY,
    REFRESH_TENANT_TOKEN,
    TENANT_TOKEN,
    SUPPLIER_TYPE,
    MYSELF,
    JOB,
    METER,
    METER_TEMPLATE,
    METER_READING,
    NOTIFICATION,
    INTEGRATION,
    COST_CENTRE,
    UNIT,
    PUBLIC_PAGE,
    ERROR_TEMPLATE,
    RECAPTCHA_VERIFY,
    POLICY,
  },
};
