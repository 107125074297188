import { Space, Tag, Typography } from "antd";
import Moment from "moment";
import { Label } from "react-bootstrap";
import SNEmpty from "../components/SN/SNEmpty";
import SNIcon from "../components/SN/SNIcon";
import AntTooltip from "../components/SN/SNTooltip";
import { GreenText, RedText, YellowText } from "../components/Text";
import Translate from "../components/Translate";
import {
  CONTENT_TYPE,
  FILE_ACCESS,
  MAX_CHAR_ELLIPSE,
  UNIT,
} from "../constant/app";
import i18n from "../i18n";
import store from "../redux";
import {
  errorAlert,
  infoAlert,
  successAlert,
  warningAlert,
} from "./alertFunctions";
import { checkFileSizeExceedLimit } from "./fileHandleLib";
import { formatMapSearchResult, InputLabel } from "./formFunctions";

const { Text } = Typography;
export const showSuccessAlert = successAlert;
export const showWarningAlert = warningAlert;
export const showInfoAlert = infoAlert;
export const showErrorAlert = errorAlert;
export const ShowLabel = InputLabel;
export const formatMapResult = formatMapSearchResult;
export const fileExceedLimit = checkFileSizeExceedLimit;

export const nestedObjectAccess = function (obj, access) {
  return access.split(".").reduce((object, item) => {
    if (object) return object[item];
    else return object;
  }, obj);
};

export const roleGenerator = (role) => {
  switch (role) {
    case 1:
      return <Translate i18nKey="user.common.group.system_owner" />;
    case 2:
      return <Translate i18nKey="user.common.group.manager" />;
    case 3:
      return <Translate i18nKey="user.common.group.service_person" />;
    default:
      return null;
  }
};
export const removeFalsy = (obj) => {
  let newObj = [];
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] || obj[prop] === "0" || obj[prop] === 0) {
      newObj.push(obj[prop]);
    }
  });
  return newObj;
};
export const addressManager = (
  address,
  isToString = false,
  hideAddress2 = false
) => {
  //Return null if Address is empty or null!
  if (!address) return "";

  let modifiedAddress = "";
  if (
    !address.address &&
    !address.post &&
    !address.city &&
    !address.country_code &&
    !address.country
  ) {
    modifiedAddress = removeFalsy([address.lat, address.lng]).join(", ");
  } else {
    modifiedAddress = removeFalsy([
      address.address,
      address.post,
      address.city,
      address.country_code,
      address.country,
    ]).join(", ");
  }

  if (hideAddress2) {
    return modifiedAddress;
  }
  if (isToString) {
    return removeFalsy([
      address.address2,
      address.address,
      address.post,
      address.city,
      address.country_code,
      address.country,
    ]).join(", ");
  }
  return (
    <div>
      {address.address2 && <p>{address.address2}</p>}
      {modifiedAddress}
    </div>
  );
};

export const getType = (id, typeObject) => {
  let item = typeObject.filter((type) => type.value === id);
  return item.length ? item[0].label : "";
};

export const errorForm = (id) => {
  switch (id) {
    case 3:
      return i18n.t("error.common.reported_from.public");
    case 2:
      return i18n.t("error.common.reported_from.web");
    case 1:
      return i18n.t("error.common.reported_from.mobile");
    default:
      return id;
  }
};

export const errorFromIcon = (id) => {
  const tooltipId = "tooltip-" + id + Math.random();

  switch (id) {
    case 3:
      return (
        <AntTooltip id={tooltipId} text={errorForm(id)} noTranslate>
          <SNIcon icon="globe" />
        </AntTooltip>
      );
    case 2:
      return (
        <AntTooltip id={tooltipId} text={errorForm(id)} noTranslate>
          <SNIcon icon="desktop" />
        </AntTooltip>
      );
    case 1:
      return (
        <AntTooltip id={tooltipId} text={errorForm(id)} noTranslate>
          <SNIcon icon="mobile" />
        </AntTooltip>
      );
    default:
      return id;
  }
};

export const errorStatusWithLabel = (status) => {
  switch (status) {
    case 3:
      return (
        <span className="label label-warning">
          {i18n.t("common_error_status_assigned")}
        </span>
      );
    case 2:
      return (
        <span className="label label-success">
          {i18n.t("common_error_status_resolved")}
        </span>
      );
    case 1:
      return (
        <span className="label label-danger">
          {i18n.t("common_error_status_active")}
        </span>
      );
    default:
      return <span className="label label-warning"></span>;
  }
};

export const assetStatus = (status, label) => {
  switch (status) {
    case 1:
      return label ? (
        <Label bsStyle="success">{i18n.t("common.label.active")}</Label>
      ) : (
        i18n.t("common.label.active")
      );
    case 2:
      return label ? (
        <Label bsStyle="default">{i18n.t("common.label.inactive")}</Label>
      ) : (
        i18n.t("common.label.inactive")
      );
    case 3:
      return label ? (
        <Label bsStyle="warning">{i18n.t("common.label.archived")}</Label>
      ) : (
        i18n.t("common.label.archived")
      );
    default:
      return i18n.t("Unknown");
  }
};

export const nodeStatus = (status, label) => {
  switch (status) {
    case 1:
      return label ? (
        <Label bsStyle="success">{i18n.t("common.label.active")}</Label>
      ) : (
        i18n.t("common.label.active")
      );
    case 2:
      return label ? (
        <Label bsStyle="warning">{i18n.t("common.label.inactive")}</Label>
      ) : (
        i18n.t("common.label.inactive")
      );
    case 3:
      return label ? (
        <Label bsStyle="default">{i18n.t("common.label.expired")}</Label>
      ) : (
        i18n.t("common.label.expired")
      );
    default:
      return i18n.t("Unknown");
  }
};

export const partsStatus = (status, label) => {
  switch (status) {
    case 1:
      return label ? (
        <Label bsStyle="success">{i18n.t("common.label.active")}</Label>
      ) : (
        i18n.t("common.label.active")
      );
    case 2:
      return label ? (
        <Label bsStyle="warning">{i18n.t("common.label.inactive")}</Label>
      ) : (
        i18n.t("common.label.inactive")
      );
    case 3:
      return label ? (
        <Label bsStyle="default">{i18n.t("common.label.archived")}</Label>
      ) : (
        i18n.t("common.label.archived")
      );
    default:
      return i18n.t("Unknown");
  }
};

export const documentAccessLabel = (access) => {
  switch (access) {
    case 1:
      return (
        <Label bsStyle="success">
          <Translate i18nKey="document.common.access.public" />
        </Label>
      );
    case 2:
      return (
        <Label bsStyle="danger">
          <Translate i18nKey="document.common.access.private" />
        </Label>
      );
    case 3:
      return (
        <Label bsStyle="warning">
          <Translate i18nKey="document.common.access.shared" />
        </Label>
      );
    default:
      return <Label bsStyle="warning">Unknown</Label>;
  }
};

export const documentStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <GreenText>
          <Translate i18nKey="common.label.active" />
        </GreenText>
      );
    case 2:
      return (
        <RedText>
          <Translate i18nKey="common.label.inactive" />
        </RedText>
      );
    case 3:
      return (
        <YellowText>
          <Translate i18nKey="document_common_status_expired" />
        </YellowText>
      );
    default:
      return <Label bsStyle="warning">Unknown</Label>;
  }
};

export const customerType = (type) => {
  switch (type) {
    case 1:
      return i18n.t("customer.common.type.company");
    case 2:
      return i18n.t("customer.common.type.private");
    default:
      return "Not found";
  }
};

export const tagsList = (tags) => {
  return (
    <ul className="list-unstyled list-inline">
      {tags.map((tag, key) => {
        return (
          <li key={key} style={{ marginRight: -5 }}>
            <Label bsStyle="primary">{tag}</Label>
          </li>
        );
      })}
    </ul>
  );
};
export const daysOfWeek = [
  "common.weekday.sunday",
  "common.weekday.monday",
  "common.weekday.tuesday",
  "common.weekday.wednesday",
  "common.weekday.thursday",
  "common.weekday.friday",
  "common.weekday.saturday",
];

export const formatDate = (date, format, showDay = false) => {
  if (!date) return null;

  const day = Moment.parseZone(date).day();

  if (showDay)
    return `${Moment.parseZone(date).format(format)} (${i18n.t(
      daysOfWeek[day]
    )})`;
  return Moment.parseZone(date).format(format);
};

export const formatDateAt = (date, warning, showDayName) => {
  if (!date) return null;

  const day = Moment.parseZone(date).day();
  const isWeekends = day === 6 || day === 0;
  return (
    <>
      <div style={{ position: "relative" }}>
        <SNIcon
          icon="calendar-check"
          style={{ color: "rgba(0, 0, 0, 0.45)" }}
        />{" "}
        {Moment.parseZone(date).format("YYYY-MM-DD")}{" "}
        {showDayName && (
          <Text type={isWeekends && "danger"}>({i18n.t(daysOfWeek[day])})</Text>
        )}
      </div>
      <div style={{ position: "relative" }}>
        <SNIcon icon="clock" style={{ color: "rgba(0, 0, 0, 0.45)" }} />{" "}
        <Space size={4}>
          {Moment.parseZone(date).format("HH:mm")} {warning}
        </Space>
      </div>
    </>
  );
};
export const formatDateWithDay = (date, format) => {
  if (!date) return null;

  const day = Moment.parseZone(date).day();
  const isWeekends = day === 6 || day === 0;

  return (
    <span>
      {`${Moment.parseZone(date).format(format)}`}{" "}
      <Text type={isWeekends && "danger"}>
        ({`${i18n.t(daysOfWeek[day])}`})
      </Text>
    </span>
  );
};
/*
 * preText(string) = Text before the formatted location sentence
 * data(object) = location data
 * postText(string) = Text after the formatted location sentence
 * */

export const locationInformation = (preText, data, postText) => {
  const { apt_number, building, level, room } = data;

  if (building || level || apt_number || room) {
    let information = i18n.t(
      "qrtag.details.additional_address.the_node_is_located"
    );

    if (building) {
      information += ` ${i18n.t(
        "qrtag.details.additional_address.in_the"
      )} ${building}`;
    }
    if (level) {
      information += ` ${i18n.t(
        "qrtag.details.additional_address.on_level"
      )} ${level}`;
    }
    if (apt_number) {
      information += ` ${i18n.t(
        "qrtag.details.additional_address.in_apartment"
      )} ${apt_number}`;
    }
    if (room) {
      information += `  ${i18n.t(
        "qrtag.details.additional_address.in_room"
      )} ${room}`;
    }
    if (postText) {
      information += ` ${postText}`;
    }

    return information;
  } else {
    return "-";
  }
};

//Single digit to trailing zero string converter, e.g. 1 to "01"
export const padding = (n) => {
  return n < 10 ? "0" + n : n;
};

//Datepicker given date is valid or not checker
export const checkValidDate = (val) => {
  if (Moment(val, "YYYY-MM-DD", true).isValid()) {
    return Moment(val).format("YYYY-MM-DD");
  } else {
    return null;
  }
};

export const companyName = (id) => {
  let companies = store.getState().cum.companies;
  let name = "";
  for (let i = 0; i < companies.length; i++) {
    if (companies[i].id === parseInt(id, 10)) {
      name = companies[i].company_name;
      break;
    }
  }

  return name;
};
export const getStatistics = () => {
  let stats = store.getState().stats;
  return stats;
};

export const transFormat = (string) => {
  if (string) {
    return string.charAt(0) + string.slice(1).toLowerCase();
  } else {
    return " ";
  }
};

/*Function for set tail message of multiple selection actions Confirm message.
 * e.g 10 asset(s) or Asset title
 * itemArray = The array of items(e.g. Selected Assets on a list)
 * itemContentKey = Item key to display on message (e.g. title)
 * message = message for multiple instance (e.g. assets)
 * */
export const messageTail = (itemArray, itemContentKey, message) => {
  if (itemArray.length > 1) {
    return `${itemArray.length} ${i18n.t(message)}`;
  } else {
    return nestedObjectAccess(itemArray[0], itemContentKey);
  }
};

export const ellipseText = (text, count = MAX_CHAR_ELLIPSE) => {
  if (text) {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }

  return null;
};

export const textArrayToObjectArray = (textArray) => {
  return textArray?.map((tag) => {
    return { label: tag, value: tag };
  });
};

export const cumUserID = (company) => {
  let companies = store.getState().cum.companies;
  let companyId = parseInt(company, 10);
  let user = null;

  for (let i = 0; i < companies.length; i++) {
    if (companies[0].company.id === companyId) {
      user = companies[0].id;
      break;
    }
  }

  return user;
};

/*
 * Merge Two Arrays using index. Created for using dynamic column in common List type component.
 *
 * Parameters:
 * actualArray:
 *   Type: Array
 *   Subject: The primary array which will be merged with the 'mergeArray'!
 *
 * mergeArray:
 *   Type: Array of objects.
 *   Subject: This array will be merged with the 'actualArray'
 *   example: [
 *               {
 *                   value: "somevalue can be any type",
 *                   index: 0
 *               },
 *               {
 *                   value: "somevalue can be any type"
 *               }
 *            ]
 *   On the above example, value is mandatory. It can be any type. index is where the value will be inserted. The count
 *   start from 0 as like array index. If index is not provided then the value will be pushed to the last index of the
 *   'actualArray' or it will be pushed as a new item in the 'actualArray'
 * */
export const mergeArraysWithIndex = (actualArray, mergeArray) => {
  const resultedArray = actualArray;

  mergeArray.map((arr) => {
    if (!arr.index) {
      return resultedArray.push(arr.value);
    } else {
      return resultedArray.splice(arr.index, 0, arr.value);
    }
  });

  return resultedArray;
};

//Set Page Title
export const setTitle = (title) => {
  document.title = `Service Node | ${i18n.t(title)}`;
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return "0 Bytes";
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const fileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/;

  return re.exec(fileName)[1];
};

/* Time to string generator.
 * Example: 26:45 will generate 26hr 45min
 * */
export function timeToString(hour, minute) {
  let timeString = "";

  if (hour !== 0) timeString = `${hour}hr`;
  if (minute !== 0) timeString += ` ${minute}min`;

  return timeString;
}

/*Simple function to add "hr" after number*/
export function toHours(hour) {
  if (!hour) return "-";

  return `${hour}hr`;
}

export function DocAccess(access) {
  const ACCESS = FILE_ACCESS();

  return ACCESS[access - 1]?.label;
}

export function timeDurationText(fromDate, toDate = null, emptyString) {
  const start = Moment(fromDate);
  const end = toDate ? Moment(toDate) : Moment();

  let time = "";

  const years = end.diff(start, "year");
  start.add(years, "years");
  if (years) {
    if (time) time += " ";
    time += `${years} ${i18n.t("common.duration.year", { count: years })}`;
  }

  const months = end.diff(start, "months");
  start.add(months, "months");
  if (months) {
    if (time) time += " ";
    time += `${months} ${i18n.t("common.duration.month", { count: months })}`;
  }

  const weeks = end.diff(start, "weeks");
  start.add(weeks, "weeks");
  if (weeks) {
    if (time) time += " ";
    time += `${weeks} ${i18n.t("common.duration.week", { count: weeks })}`;
  }

  const days = end.diff(start, "days");
  start.add(days, "days");
  if (days) {
    if (time) time += " ";
    time += `${days} ${i18n.t("common.duration.day", { count: days })}`;
  }

  const hours = end.diff(start, "hours");
  start.add(hours, "hours");
  if (hours) {
    if (time) time += " ";
    time += `${hours} ${i18n.t("common.duration.hour", { count: hours })}`;
  }

  const minutes = end.diff(start, "minutes");
  if (minutes) {
    if (time) time += " ";
    time += `${minutes} ${i18n.t("common.duration.min", { count: minutes })}`;
  }

  if (time === "" && emptyString) time = `${i18n.t(emptyString)}`;
  if (time === "" && !emptyString)
    time = i18n.t("common.duration.hour_minute", { hour: 0, min: 0 });
  return time;
}

export function partsUnit(unitId) {
  const units = UNIT();

  const unitIndex = units.findIndex((unit) => unit.value === unitId);

  if (unitIndex < 0) return "";

  return units[unitIndex].label;
}

export function addDurations(times) {
  if (times.length === 0) return "00:00:00";

  let hours = 0;
  let minutes = 0;

  for (let index = 0; index < times.length; index++) {
    const time_parts = times[index].split(":");

    hours += parseInt(time_parts[0], 10);
    minutes += parseInt(time_parts[1], 10);
  }

  hours += parseInt(minutes / 60, 10);
  const remain_min = minutes % 60;

  return padding(hours) + ":" + padding(remain_min) + ":00";
}

export const isEmpty = (arr) => arr.length === 0;
export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
export const generateFullName = (first_name, last_name) =>
  `${first_name || ""} ${last_name || ""}`;

//Find the first contact( if available ) of an entity(e.g. partner, company, work order, customer etc) details.
export const findContact = (contactsArray) => {
  if (Array.isArray(contactsArray) && !isEmpty(contactsArray)) {
    const { first_name, last_name, contact } = contactsArray[0].contact_person;

    return {
      name: generateFullName(first_name, last_name),
      phone1: contact ? contact.phone1 : null,
      phone2: contact ? contact.phone2 : null,
      email: contact ? contact.email : null,
    };
  } else {
    return { name: null, phone1: null, phone2: null, email: null };
  }
};

export function current_date(date_format = "YYYY-MM-DD") {
  return new Moment().format(date_format);
}

export function generateTextFromArray(arr) {
  return arr.filter(Boolean).join(", ");
}

export function generateContactSummary(contactDetails) {
  if (!contactDetails || !contactDetails.hasOwnProperty("contact_person"))
    return null;

  const { first_name, last_name, contact } = contactDetails.contact_person;
  const contact_name = generateFullName(first_name, last_name);
  let contact_email = null;
  let contact_phone = null;

  if (contact) {
    contact_email = contact.email;
    contact_phone = contact.phone1;
  }

  return generateTextFromArray([contact_name, contact_email, contact_phone]);
}

// Returns if a value is an object
export function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}

export const statusContractValue = (status) => {
  if (!status) return "";
  switch (status) {
    case 1:
      return (
        <Label bsStyle="primary">
          <Translate i18nKey="contract_status_draft" />
        </Label>
      );
    case 2:
      return (
        <Label bsStyle="success">
          <Translate i18nKey="common.label.active" />
        </Label>
      );
    case 3:
      return (
        <Label bsStyle="warning">
          <Translate i18nKey="common.label.inactive" />
        </Label>
      );
    case 4:
      return (
        <Label bsStyle="danger">
          <Translate i18nKey="common.label.expired" />
        </Label>
      );
    default:
      return <Label bsStyle="warning">Unknown</Label>;
  }
};

export function AssetStatusTag({ status }) {
  if (!status) return null;
  switch (status) {
    case 1:
      return null;
    case 2:
      return (
        <Tag color="#2db7f5" style={{ marginLeft: "5px" }}>
          <Translate i18nKey="common.label.inactive" />
        </Tag>
      );
    case 3:
      return (
        <Tag color="#f50" style={{ marginLeft: "5px" }}>
          <Translate i18nKey="common.label.archived" />
        </Tag>
      );
    default:
      return null;
  }
}

export function CustomerStatusTag({ status }) {
  if (!status) return null;
  switch (status) {
    case 1:
      return null;
    case 2:
      return (
        <Tag color="#2db7f5" style={{ marginLeft: "5px" }}>
          <Translate i18nKey="common.label.inactive" />
        </Tag>
      );
    case 3:
      return (
        <Tag color="#108ee9" style={{ marginLeft: "5px" }}>
          <Translate i18nKey="Pending" />
        </Tag>
      );
    case 4:
      return (
        <Tag color="#f50" style={{ marginLeft: "5px" }}>
          <Translate i18nKey="common.label.archived" />
        </Tag>
      );
    default:
      return null;
  }
}
export function formPostActions(history, popFallbackUrl, params = {}) {
  if (history.action === "POP" && !history.location.key)
    history.push(popFallbackUrl, params);
  else {
    history.goBack();
  }
}

export function getTimeLogJobEntity(content_type, entity) {
  if (!content_type)
    return <SNEmpty title="timelog.list.col.no_jobs_attached" />;
  return (
    <span>
      <Translate i18nKey={CONTENT_TYPE[content_type]?.name || ""} />{" "}
      <b>
        {entity ? (
          `#${entity}`
        ) : (
          <Translate i18nKey="timelog.list.col.job_is_deleted" />
        )}
      </b>
    </span>
  );
}
