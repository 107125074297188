import comAPI, { url } from "../../app/comAPI";
import { FRIENDLY_CAPTCHA_KEY } from "../../config";

export const onCheckSolution = async (solution) => {
  const sitekey = FRIENDLY_CAPTCHA_KEY;
  const params = {
    solution,
    sitekey,
  };
  return await comAPI.postNoLoading(`${url.RECAPTCHA_VERIFY}`, params);
};
