import { Route, RouteProps } from "react-router-dom";
import AuthGuard from "../../../guards/AuthGuard";

interface AuthorizedRoutePops extends RouteProps {
  component: any;
  render?: (props: any) => React.ReactNode;
  redirectPath?: string;
  redirectState?: any;
}

const AuthorizedRoute: React.FC<AuthorizedRoutePops> = ({
  component: Component,
  render,
  redirectPath = "/login",
  redirectState = {},
  ...rest
}) => {
  return (
    <AuthGuard>
      <Route
        {...rest}
        render={(props: any) =>
          Component ? <Component {...props} /> : render(props)
        }
      />
    </AuthGuard>
  );
};

export default AuthorizedRoute;
