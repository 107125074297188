import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuCollapsed: false,
};
const themeSlice = createSlice({
  name: "themeTodos",
  initialState,
  reducers: {
    toggleMenuColapsed(state, action) {
      return {
        ...state,
        menuCollapsed: action.payload,
      };
    },
  },
});

const { actions, reducer } = themeSlice;

export const { toggleMenuColapsed } = actions;

export default reducer;
