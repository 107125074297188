import axios from "axios";
import "url-search-params-polyfill";
import api, { url } from "../app/appAPI";
import { TOKEN_CONFIG } from "../config/settings";
import { CLIENT_ID, CLIENT_SECRET } from "../constant/app";
import store from "../redux";

/*function getUserRole(auth_token, email, password) {
    const config = {
        headers: {
            'Authorization': auth_token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    const userParams = new URLSearchParams();
    userParams.append('username', email);
    userParams.append('password', password);

    store.dispatch((dispatch) => {
        dispatch({
            type: 'GET_USER_ROLE_START'
        });

        axios.post('/api/v1_1/user/login/', userParams, config)
            .then((response) => {
                dispatch({
                    type: 'GET_USER_ROLE_RECEIVED',
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'GET_USER_ROLE_FAILED',
                    payload: error.response
                })
            })
    });
}*/

export function userLogin(data) {
  const email = data.email;
  const password = data.password;

  const params = new URLSearchParams();
  params.append("username", email);
  params.append("password", password);
  params.append("grant_type", "password");

  store.dispatch((dispatch) => {
    dispatch({
      type: "USER_VERIFICATION_START",
    });
    axios
      .post("/o/token/", params, TOKEN_CONFIG)
      .then((response) => {
        dispatch({
          type: "USER_VERIFICATION_SUCCESSFUL",
          payload: {
            token: response.data.access_token,
            refresh_token: response.data.refresh_token,
            loginTime: new Date(),
          },
        });
        //getUserRole('Bearer ' + response.data.access_token, email, password);
      })
      .catch((error) => {
        let error_status = error.response.status;

        if (error_status === 400 || error_status === 401) {
          dispatch({
            type: "USER_VERFICATION_FAILED",
            payload: error_status,
          });
        } else if (error_status === 404) {
          dispatch({
            type: "SERVER_NOT_RESPOND",
            payload: error_status,
          });
        }
      });
  });

  return {
    type: "USER_LOGIN",
    payload: data,
  };
}

export const logout = async () => {
  api
    .post(
      url.REVOKE_TOKEN,
      {
        token: store.getState().auth.access_token,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      },
      true
    )
    .then((response) => {});

  store.dispatch({
    type: "LOGOUT",
  });
};

export function resetLoginMessages() {
  store.dispatch({
    type: "RESET_LOGIN_MESSAGES",
  });
}
export function changeCompany(company) {
  store.dispatch({
    type: "CHANGE_COMPANY",
    payload: company,
  });
}
export function selectCompany(company) {
  store.dispatch({
    type: "SELECT_COMPANY",
    payload: company,
  });
}
