import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  date_joined: null,
  user_token: null,
  tos_accept_date: null,
  username: null,
  first_name: null,
  last_name: null,
  contact: {
    email: null,
    phone1: null,
    phone2: null,
  },
  language: null,
  image: null,
  address: null,
  is_active: true,
  tos_accepted: true,
};
const userSlice = createSlice({
  name: "userTodos",
  initialState,
  reducers: {
    fetchUserDataSuccess(state, action) {
      return {
        ...action.payload,
      };
    },
    acceptTOS(state, action) {
      return {
        ...state,
        tos_accepted: action.payload,
      };
    },
  },
});

const { actions, reducer } = userSlice;

export const { fetchUserDataSuccess, acceptTOS } = actions;

export default reducer;
