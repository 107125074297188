import api, { url } from "../../../app/appAPI";
import { showSuccessAlert } from "../../../lib";
import stote from "../../../redux";
import { acceptTOS } from "../../../redux/reducers/userReducer";
export default function acceptTos(setLoading) {
  setLoading(true);
  api
    .patch(url.MY_PROFILE, {
      tos_accepted: true,
    })
    .then((response) => {
      if (response && response.status === 200) {
        showSuccessAlert("terms_of_service.accept_success_text", false, false);

        stote.dispatch(acceptTOS(true));
      }
      setLoading(false);
    });
}
