import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_token: null,
  token: null,
  refresh_token: null,
  errorMsg: null,
  loggingIn: false,
  tokenIsInvalid: false,
  isRefreshTokenLoading: false,
  loginTime: null,
};

const authSlice = createSlice({
  name: "loginTodos",
  initialState,
  reducers: {
    userLoginSuccess(state, action) {
      return {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        loginTime: action.payload.loginTime,
        errorMsg: null,
        loggingIn: false,
      };
    },
    refreshTokenStart(state) {
      return {
        ...state,
        tokenIsInvalid: false,
        isRefreshTokenLoading: true,
      };
    },
    refreshTokenSuccess(state, action) {
      return {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        tokenIsInvalid: true,
        isRefreshTokenLoading: false,
      };
    },
    makeTokenValid(state) {
      return {
        ...state,
        tokenIsInvalid: false,
      };
    },
    serverNotRespond(state) {
      return {
        ...state,
        loggingIn: false,
        errorMsg: "serverIssueMsg",
      };
    },
    resetLoginMessages(state) {
      return {
        ...state,
        loggingIn: false,
        errorMsg: null,
      };
    },
    userLogout(state) {
      return {
        access_token: null,
        token: null,
        refresh_token: null,
        errorMsg: null,
        loggingIn: false,
        tokenIsInvalid: false,
        loginTime: null,
        isRefreshTokenLoading: false,
      };
    },
  },
});

const { actions, reducer } = authSlice;

export const {
  userLoginSuccess,
  refreshTokenStart,
  refreshTokenSuccess,
  makeTokenValid,
  serverNotRespond,
  resetLoginMessages,
  userLogout,
} = actions;

export default reducer;
